import React from 'react'
import PropTypes from 'prop-types'

import './index.scss'

export default class AtLoading extends React.Component {
    constructor () {
        super(...arguments);
    }

    render () {
        const { color, size } = this.props;
        const sizeStyle = {
            width: size || '',
            height: size || '',
        };
        const colorStyle = {
            'border': color ? `1px solid ${color}` : '',
            borderColor: color ? `${color} transparent transparent transparent` : '',
        };
        const ringStyle = Object.assign({}, colorStyle, sizeStyle);

        return (
            <div className='at-loading' style={sizeStyle}>
                <div className='at-loading__ring' style={ringStyle}></div>
                <div className='at-loading__ring' style={ringStyle}></div>
                <div className='at-loading__ring' style={ringStyle}></div>
            </div>
        )
    }
}

AtLoading.defaultProps = {
    size: 0,
    color: '',
}

AtLoading.propTypes = {
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    color: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
}
