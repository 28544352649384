import {
    ADD_TO_CART,
    MODIFY_ITEM_COUNT,
    MODIFY_ITEM_CHECKED,
    DEL_ITEMS,
    DEL_ALL
} from '../constants/carts'

export const addToCart = (item) => {
    return dispatch => {
        dispatch({
            type: ADD_TO_CART,
            item
        });
    };
};
export const modifyItemCount = (index, stepperVal) => {
    return dispatch => {
        dispatch({
            type: MODIFY_ITEM_COUNT,
            index,
            stepperVal
        });
    };
};
export const modifyItemChecked = (index, checked) => {
    return dispatch => {
        dispatch({
            type: MODIFY_ITEM_CHECKED,
            index,
            checked
        });
    };
};
export const delItems = () => {
    return dispatch => {
        dispatch({
            type: DEL_ITEMS
        });
    };
};
export const delAll = () => {
    return dispatch => {
        dispatch({
            type: DEL_ALL
        });
    };
};