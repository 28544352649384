import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { addToCart, modifyItemCount } from '../../actions/carts'
import { Link } from 'react-router-dom'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'
import { loadOptions } from '../../utils/common-api'
import {copyData, hasItem, getItemIndex, getSourceArrayField, getAllUrlParam} from '../../utils/common'
import event from '../../utils/event'
import { Carousel, Toast } from 'antd-mobile'
import SearchInput from '../../components/search-input'
import ScrollTab from '../../components/scroll-tab'
import ProductSection from '../../components/product-section'
import FabCart from '../../components/fab-cart'
import FabBackTop from '../../components/fab-back-top'
import FabOrderList from '../../components/fab-order-list'
import CartPopup from '../../components/cart-popup'
import SkeletonBannerSection from '../../components/skeleton/banner-section'
import SkeletonProductSection from '../../components/skeleton/product-section'
import AtLoadMore from '../../components/load-more'
import fallsDecorator from '../../hocs/fallsDecorator'
import backTopDecorator from '../../hocs/backTopDecorator'
import entryDecorator from '../../hocs/entryDecorator'

import './index.scss'
import styles from './index.module.scss'
import tick from '../../images/icons/tick-circle.png'
import {set as setGlobalData} from "../../utils/global-data";
import {INVITE_ID} from "../../constants/global";

let _tx = 0, _ty = 0;
let startTimer = null, endTimer = null;

function resetCartVars() {
    _tx = 0;
    _ty = 0;
}

const mapStateToProps = store => ({
    carts: store.carts,
    userInfo: store.userInfo
});
const mapDispatchToProps = dispatch => ({
    addToCart: item => {
        dispatch(addToCart(item));
    },
    modifyItemCount: (index, stepperVal) => {
        dispatch(modifyItemCount(index, stepperVal));
    },
});
class Index extends React.Component {
    state = {
        categoryList: [],
        bannerList: [],
        activeTabIndex: 0,
        cartAnimationModel: {
            tx: 0,
            ty: 0
        },
        startAddToCartAnimation: false,
        showCartPopup: false,
        addItem: {},
    }
    componentDidMount() {
        const params = getAllUrlParam(this.props.location.search.slice(1));
        const inviteId = params.hasOwnProperty('inviteId') && params.inviteId;
        if (inviteId) {
            setGlobalData(INVITE_ID, inviteId);
        }

        this.fabCart = React.createRef();
        this.loadCategoryList();
        this.loadBannerList();
    }
    componentWillUnmount() {
        event.removeAllListeners();
    }
    async loadCategoryList() {
        let res = await Fetch.get(api.getCategoryList);
        this.setState({
            categoryList: res.data
        });
        event.emit('init', api.getAllGoodsIndexList + `?CategoryId=${res.data[0].Id}&Toped=1`);
    }
    async loadBannerList() {
        let res = await Fetch.get(api.getBannerList + '?BannerType=3');
        this.setState({
            bannerList: res.data
        });
    }
    linkSearch = () => {
        this.props.history.push('/search');
    }
    onProductItem(item) {
        this.props.history.push(`/productDetail/${item.Id}`);
    }
    async onCart(item, cartRect) {
        let fabCartRect = this.fabCart.current.getBoundingClientRect();
        let offsetRight = (fabCartRect.right - cartRect.right) - cartRect.width / 2;
        let offsetBottom = (fabCartRect.bottom - cartRect.bottom) - cartRect.height / 2;

        _tx = offsetRight;
        _ty = offsetBottom;

        let _addItem = await loadOptions(item);

        this.setState({
            showCartPopup: true,
            addItem: _addItem
        });
    }
    updateCart() {
        const { addItem } = this.state;
        let result = hasItem(this.props.carts, addItem);
        if (!result) {
            this.props.addToCart(addItem);
        } else {
            let modifyItemIndex = getItemIndex(this.props.carts, addItem);
            let modifyStepperVal = addItem.stepperVal + this.props.carts[modifyItemIndex].stepperVal;
            this.props.modifyItemCount(modifyItemIndex, modifyStepperVal);
        }
    }
    onCartProductImgTransitionEnd() {
        this.setState((state) => ({
            cartAnimationModel: {
                ...state.cartAnimationModel,
                tx: 0,
                ty: 0
            },
            startAddToCartAnimation: false
        }));
        this.updateCart();
    }
    closeCartPopup() {
        resetCartVars();
        this.setState({ showCartPopup: false });
    }
    async resetAddItem(item) {
        let _obj = await loadOptions(item);
        const originGoodsImageUrl = getSourceArrayField(this.props.fallsData.falls, item, 'GoodsImageUrl');

        let _addItem = copyData(item);
        _addItem.GoodsImageUrl = originGoodsImageUrl;
        _addItem.GoodsNum = _obj.GoodsNum;
        _addItem.GoodsPrice = null;

        this.setState({
            addItem: _addItem
        });
    }
    async onOptionTag(optionsTag) {
        let _addItem;
        if (optionsTag.length === this.state.addItem.GoodsGroup.length) {
            let res = await Fetch.get(api.getGoodsSpecDetailGroupInfo + `?GoodsGroup=${JSON.stringify(optionsTag)}`);
            _addItem = {
                ...this.state.addItem,
                ...res.data,
                GoodsImageUrl:
                    res.data.GoodsImageUrl
                        ? res.data.GoodsImageUrl
                        : getSourceArrayField(this.props.fallsData.falls, this.state.addItem, 'GoodsImageUrl')
            };
            this.setState({
                addItem: _addItem
            });
        } else {
            this.resetAddItem(this.state.addItem);
        }
    }
    onConfirm(optionsTag, stepperVal) {
        if (startTimer) {
            clearTimeout(startTimer);
        }
        if (endTimer) {
            clearTimeout(endTimer);
        }
        if (optionsTag.length !== this.state.addItem.GoodsGroup.length) {
            Toast.info('请选择完整规格', 1);
        } else {
            console.log(optionsTag);
            const { addItem } = this.state;
            let _addItem = copyData(addItem);
            _addItem.stepperVal = Math.min(_addItem.GoodsNum, stepperVal);
            _addItem.optionsTag = optionsTag;
            this.setState({
                showCartPopup: false,
                addItem: _addItem
            });
            startTimer = setTimeout(() => {
                this.setState({ startAddToCartAnimation: true });
            }, 450);
            endTimer = setTimeout(() => {
                this.setState((state) => ({
                    cartAnimationModel: {
                        ...state.cartAnimationModel,
                        tx: _tx,
                        ty: _ty
                    }
                }));
                resetCartVars();
            }, 500);
        }
    }
    onChangeTab = (index) => {
        const { categoryList } = this.state;
        event.emit('changeTab', api.getAllGoodsIndexList + `?CategoryId=${categoryList[index].Id}&Toped=${Number(index) ? 2 : 1}&MemberId=${this.props.userInfo.Id}`);
        this.setState({
            activeTabIndex: index
        });
    }
    onFabBackTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    renderHeader() {
        const { activeTabIndex, categoryList } = this.state;

        return (
            <header className={styles.header}>
                {/*<div className={styles['search-box']}>
                    <SearchInput
                        editable={false}
                        placeholder='大家都在搜索#耳道空气清新精油#'
                        onLink={this.linkSearch} />
                </div>*/}
                <div className={styles['scroll-box']} style={{marginTop: 0}}>
                    <ScrollTab
                        tabs={categoryList}
                        fields={{name: 'CategoryName'}}
                        activeIndex={activeTabIndex}
                        onChangeTab={this.onChangeTab} />
                </div>
            </header>
        )
    };
    renderCarouselBox() {
        const { bannerList } = this.state;
        const brandTexts = ['正品保证', '厂家直销', '售后无忧'];

        return (
            <div className={styles['carousel-section']}>
                <div className={styles['carousel-box']}>
                    <Carousel
                        className='index-carousel'
                        autoplay
                        infinite
                        dotStyle={{background: '#ffffff', marginBottom: '10px'}}
                        dotActiveStyle={{background: '#ffffff', opacity: '0.6', marginBottom: '10px'}}>
                        {
                            bannerList.map((item, index) => {
                                return (
                                    <Link
                                        key={`banner-${index}`}
                                        className={styles.banner}
                                        to={item.PageUrl}
                                        style={{
                                            backgroundImage: `url(${item.Image})`
                                        }}>

                                    </Link>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className={styles['carousel-brand-box']}>
                    {
                        brandTexts.map((item, index) => {
                            return (
                                <div key={`brandTexts-${index}`} className={styles['brand-box']}>
                                    <img className={styles['brand-img']} src={tick} alt=""/>
                                    <p className={styles['brand-text']}>{item}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
    renderSkeletonBannerSection() {
        return <SkeletonBannerSection />
    }
    renderProductSection() {
        const { fallsData } = this.props;
        const { cartAnimationModel, startAddToCartAnimation } = this.state;

        return (
            <div className={styles['product-box']}>
                { fallsData.falls.length > 0
                    ? <ProductSection
                        list={fallsData.falls}
                        startAnimation={startAddToCartAnimation}
                        cartAnimationModel={cartAnimationModel}
                        onProductItem={this.onProductItem.bind(this)}
                        onCart={this.onCart.bind(this)}
                        onCartProductImgTransitionEnd={this.onCartProductImgTransitionEnd.bind(this)} />
                    : <SkeletonProductSection />
                }
            </div>
        )
    }
    renderFAB() {
        const { backTopData, carts } = this.props;
        const cartsCount = carts
            .map(el => el.stepperVal || 1)
            .reduce((acc, cur) => acc + cur, 0);

        return (
            <div className={styles['fab-groups']}>
                <FabCart
                    fabCartRef={this.fabCart}
                    count={cartsCount} />
                <FabOrderList />
                <FabBackTop
                    show={backTopData.showBackTop}
                    onClick={this.onFabBackTop.bind(this)} />
            </div>
        )
    }
    renderCartPopup() {
        const { showCartPopup, addItem } = this.state;

        return (
            <CartPopup
                show={showCartPopup}
                item={addItem}
                onConfirm={this.onConfirm.bind(this)}
                onClose={this.closeCartPopup.bind(this)}
                onOptionTag={this.onOptionTag.bind(this)} />
        )
    }
    renderLoadMore() {
        const { fallsExtra } = this.props;

        return (
            <div className='load-more-wrapper' style={{ visibility: fallsExtra.initLoad ? 'hidden' : 'visible' }}>
                <AtLoadMore status={fallsExtra.loadMoreStatus} />
            </div>
        )
    }
    render() {
        const { fallsData } = this.props;

        return (
            <>
                {this.renderHeader()}
                {fallsData.falls.length > 0 && this.renderCarouselBox()}
                {!fallsData.falls.length && this.renderSkeletonBannerSection()}
                {this.renderProductSection()}
                {this.renderFAB()}
                {this.renderCartPopup()}
                {this.renderLoadMore()}
            </>
        )
    }
}

Index = connect(mapStateToProps, mapDispatchToProps)(Index) || Index;
Index = entryDecorator(Index) || Index;
Index = fallsDecorator(Index) || Index;
Index = backTopDecorator(Index) || Index;
export default Index