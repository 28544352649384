import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'
import event from '../../utils/event'
import { isEmptyObject, getAllUrlParam } from '../../utils/common'
import { ENV_ALIPAY, ENV_WEAPP } from '../../utils/compat'
import { orderStatusMap } from '../../utils/mapping-table'
import { Toast } from 'antd-mobile'
import OrderListItem from '../../components/order-list-item'
import EmptyBox from '../../components/empty-box'
import FabHome from '../../components/fab-home'
import CustomLoadingIcon from '../../components/custom-loading-icon'
import ReachBottomDecorator from '../../hocs/reachBottomDecorator'
import EntryDecorator from '../../hocs/entryDecorator'

import './list.scss'
import styles from './list.module.scss'
import orderCategory1 from '../../images/icons/order-category-1.png'
import orderCategory2 from '../../images/icons/order-category-2.png'
import orderCategory3 from '../../images/icons/order-category-3.png'
import orderCategory4 from '../../images/icons/order-category-4.png'
import orderCategory5 from '../../images/icons/order-category-5.png'
import normalEmpty from '../../images/common/normal-empty.png'

let pageIndex = 1;
let hasMore = true;

const statusMap = {
    all: 10,
    unpaid: 0,
    unreceived: 1,
    uncomment: 2,
    afterSale: 3
};
const orderCategoryList = [
    { id: statusMap.all, name: '全部订单', img: orderCategory1 },
    { id: statusMap.unpaid, name: '待付款', img: orderCategory2 },
    { id: statusMap.unreceived, name: '待收货', img: orderCategory3 },
    { id: statusMap.uncomment , name: '待评价', img: orderCategory4 },
    { id: statusMap.afterSale, name: '售后', img: orderCategory5 },
];

const mapStateToProps = store => ({
    checkedAddressItem: store.address,
    appType: store.appType
});
class OrderList extends React.Component {
    state = {
        activeCateId: orderCategoryList[0].id,
        orderList: [],
        addressList: [],
    }
    componentDidMount() {
        const params = getAllUrlParam(this.props.location.search.slice(1));
        const memberId = params.hasOwnProperty('memberId') && params.memberId;

        event.on('reachBottom', () => {
            if (!hasMore) return;
            this.loadOrderList(++pageIndex, memberId)
                .then(data => {
                    if (Array.isArray(data) && data.length === 0) {
                        hasMore = false;
                    }
                    this.handleOrderList(data);
                })
        });
        this.initCateId().then(() => this.initOrderList(memberId));
        this.loadAddressList(memberId);
    }
    componentWillUnmount() {
        event.removeAllListeners();
    }
    async loadAddressList(memberId) {
        let res = await Fetch.get(api.getMemberAddressList + `?MemberId=${memberId || this.props.userInfo.Id}`);
        this.setState({
            addressList: res.data
        })
    }
    initCateId() {
        let status = getAllUrlParam(this.props.location.search.slice(1)).status;
        if (!status && status != 0) {
            status = statusMap.all;
        }
        return new Promise(resolve => {
            this.setState({
                activeCateId: status
            }, () => resolve());
        });
    }
    initOrderList(memberId) {
        pageIndex = 1;
        hasMore = true;
        this.loadOrderList(pageIndex, memberId).then((data) => {
            this.setState({
                orderList: data
            });
        });
    }
    async loadOrderList(pageIndex, memberId) {
        Toast.loading(<CustomLoadingIcon />, 15);
        let res = await Fetch.get(api.getGoodsOrderByMemberId + `?MemberId=${memberId || this.props.userInfo.Id}&Status=${this.state.activeCateId}&PageIndex=${pageIndex}`);
        Toast.hide();
        return res.data;
    }
    handleOrderList(array) {
        const { orderList } = this.state;
        this.setState({
            orderList: [...orderList, ...array]
        });
    }
    getOrderModel(item) {
        const { checkedAddressItem } = this.props;
        const { addressList } = this.state;

        let memberAddressId = isEmptyObject(checkedAddressItem) ? addressList[0].Id : checkedAddressItem.Id;
        let goodsDetailList = [...item.OrderDetail].map(item => ({
            GoodsId: item.GoodsId,
            GoodsSpecDetailGroupId: item.GoodsSpecDetailGroupId,
            GoodsPrice: item.TotalPrice,
            stepperVal: item.GoodsNum
        }));

        return {
            MemberAddressId: memberAddressId,
            OrderNo: item.OrderNo,
            GoodsRemark: item.GoodsRemark,
            GoodsDetailList: goodsDetailList,
            TotalMoney: item.OrderPrice,
            CouponsPrice: 0
        }
    }
    onLinkHome() {
        this.props.history.replace('/');
    }
    onLinkMiniProgram() {
        switch (this.props.appType) {
            case ENV_WEAPP:
                window.wx.miniProgram.switchTab({
                    url: '/pages/index/index'
                });
                break;
            case ENV_ALIPAY:
                window.my.switchTab({
                    url: '/pages/index/index'
                });
                break;
            default:
                window.wx.miniProgram.switchTab({
                    url: '/pages/index/index'
                });
        }
    }
    onCateItem(item) {
        this.setState({
            activeCateId: item.id
        }, () => {
            this.initOrderList();
        })
    }
    onOrderItem(order, item) {
        // if (item.OrderStatus === statusMap.unpaid) {
        //     this.props.history.push({ pathname: '/orderDetail', query: { item } });
        //     return;
        // }
        this.props.history.push(`/productDetail/${order.GoodsId}`);
    }
    onOrderLink(item) {
        // if (item.OrderStatus === statusMap.unpaid) {
        //     this.props.history.push({ pathname: '/orderDetail', query: { item } });
        // }
    }
    onComment(item) {
        this.props.history.push({
            pathname: '/comment/',
            query: {
                orderDetail: JSON.stringify(item.OrderDetail),
                goodsImg: item.OrderDetail[0].GoodsImageUrl,
                goodsName: item.OrderDetail[0].GoodsName,
                orderNo: item.OrderNo,
            }
        });
    }
    onPay(item) {
        switch (this.props.appType) {
            case ENV_WEAPP:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/orderV2/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel(item)))}`
                });
                break;
            case ENV_ALIPAY:
                window.my.navigateTo({
                    url: `/pages/order/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel(item)))}`
                });
                break;
            default:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/orderV2/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel(item)))}`
                });
        }
    }
    onLinkLogistics(item) {
        this.props.history.push({
            pathname: `/logistics/${item.OrderNo}`,
            query: {
                goodsImg: item.OrderDetail[0].GoodsImageUrl,
                totalCount: item.GoodsOrderNum
            }
        });
    }
    renderCategoryList() {
        const { activeCateId } = this.state;

        return (
            <div className={styles['order-category-list']}>
                {
                    orderCategoryList.map((item, index) => {
                        return (
                            <div
                                key={`order-category-item-${index}`}
                                className={styles['order-category-item']}
                                onClick={this.onCateItem.bind(this, item)}>
                                <div
                                    className={classNames(
                                        styles['order-category-img'],
                                        {
                                            [styles['order-category-img--active']]: activeCateId == item.id
                                        }
                                    )}
                                    style={{backgroundImage: `url(${item.img})`}}>

                                </div>
                                <p className={styles['order-category-text']}>{item.name}</p>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    renderOrderList() {
        const { orderList } = this.state;

        return (
            <div className={styles['order-list']}>
                {
                    orderList.map((order, order_index) => {
                        return (
                            <OrderListItem
                                key={`order-list-${order_index}`}
                                itemClassName='order-list-bills-item'
                                item={order}
                                billsItemField={{
                                    goodsName: 'GoodsName',
                                    goodsPrice: 'TotalPrice'
                                }}
                                onItem={this.onOrderItem.bind(this)}
                                onLink={this.onOrderLink.bind(this)}>
                                { (order.OrderStatus === statusMap.unpaid)
                                    && (
                                    <button
                                        className={classNames(
                                            styles['order-light-button'],
                                            styles['order-button']
                                        )}
                                        onClick={this.onPay.bind(this, order)}>
                                        立即付款
                                    </button>
                                    )
                                }
                                { (order.OrderStatus === orderStatusMap.shipped || order.OrderStatus === orderStatusMap.received)
                                    && (
                                    <button
                                        className={classNames(
                                            styles['order-normal-button'],
                                            styles['order-button']
                                        )}
                                        onClick={this.onLinkLogistics.bind(this, order)}>
                                        查看物流
                                    </button>
                                    )
                                }
                                { (order.OrderStatus === orderStatusMap.received)
                                    && (
                                        <button
                                            className={classNames(
                                                styles['order-light-button'],
                                                styles['order-button']
                                            )}
                                            onClick={this.onComment.bind(this, order)}>
                                            评价
                                        </button>
                                    )
                                }
                            </OrderListItem>
                        )
                    })
                }
            </div>
        )
    }
    renderEmptyBox() {
        return (
            <>
                <EmptyBox
                    emptyImageClassName={styles['empty-image']}
                    emptyTextClassName={styles['empty-text']}
                    emptyImage={normalEmpty}
                    emptyText='还没有订单哦~' />
                <button className='back-home-button' style={{marginTop: '40px'}} onClick={this.onLinkHome.bind(this)}>去商城逛逛吧</button>
            </>
        )
    }
    renderFAB() {
        return (
            <div className={styles['fab-groups']}>
                <FabHome onClick={this.onLinkMiniProgram.bind(this)} />
            </div>
        )
    }
    render() {
        const { orderList } = this.state;

        return (
            <>
                {this.renderCategoryList()}
                { orderList.length > 0
                    ? this.renderOrderList()
                    : this.renderEmptyBox()
                }
                {this.renderFAB()}
            </>
        )
    }
}

OrderList = connect(mapStateToProps)(OrderList) || OrderList;
OrderList = EntryDecorator(OrderList) || OrderList;
OrderList = ReachBottomDecorator(OrderList) || OrderList;
export default OrderList