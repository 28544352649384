import React from 'react'
import html2canvas from 'html2canvas'
import { connect } from 'react-redux'
import { getAvatarUrl, getNick } from '../../utils/compat'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'

import styles from './poster.module.scss'
import logoPng from '../../images/common/logo.png'

const mapStateToProps = store => ({
    userInfo: store.userInfo
});

const posterBoard = React.createRef();
class ProductPoster extends React.Component {
    state = {
        wrapperHeight: document.documentElement.clientHeight,
        dataUrl: '',
        detailItem: {
            GoodsImage: [],
            GoodsRemarkImage: [],
            MinPrice: 0
        },
        qrcodeImg: ''
    }
    componentDidMount() {
        const { item } = this.props.location.query;
        this.setState({
            detailItem: item
        }, () => {
            this.loadQrcode().then(() => {
                // this.loadDataUrl();
            })
        });
    }
    async loadQrcode() {
        let res = await Fetch.get(api.getImageCode + `?GoodsId=${this.state.detailItem.Id}&inviteId=${this.props.userInfo.Id}`);
        return new Promise(resolve => {
            this.setState({
                qrcodeImg: res.data
            }, () => resolve());
        });
    }
    loadDataUrl() {
        html2canvas(posterBoard.current, {
            useCORS: true,
            // scale: 0.8
        })
            .then(canvas => {
                this.setState({
                    dataUrl: canvas.toDataURL()
                });
            })
    }
    renderPosterBoard() {
        const { detailItem, dataUrl, qrcodeImg } = this.state;
        const { userInfo } = this.props;

        return (
            <div
                ref={posterBoard}
                className={styles['poster-board']}>
                {
                    dataUrl && <img className={styles['canvas-img']} crossOrigin='anonymous' src={dataUrl} alt=""/>
                }
                <div className={styles['poster-board-top']}>
                    <div className={styles['poster-avatar']} style={{backgroundImage: `url(${getAvatarUrl(userInfo)})`}}>

                    </div>
                    <div className={styles['poster-top-center']}>
                        <h2 className={styles['poster-username']}>{getNick(userInfo)}</h2>
                        <p className={styles['poster-desc']}>分享给你一个商品</p>
                    </div>
                    <img className={styles['poster-logo']} src={logoPng} alt=""/>
                </div>
                <div
                    className={styles['product-bg-img']}
                    style={{backgroundImage: `url(${detailItem.GoodsImageUrl}?timestamp=${+new Date()})`}}>

                </div>
                <div className={styles['poster-board-bottom']}>
                    <div className={styles['poster-bottom-left']}>
                        <p className={styles['product-title']}>{detailItem.GoodsName}</p>
                        <p className={styles['product-price']}><span className={styles['product-price-yuan']}>￥</span>{detailItem.MinPrice}</p>
                        <p className={styles['product-desc']}>长按识别二维码访问</p>
                    </div>
                    <img className={styles['poster-qrcode']} src={qrcodeImg} alt=""/>
                </div>
            </div>
        )
    }
    renderOther() {
        return (
            <div className={styles['bottom-box']}>
                {/*<p className={styles['save-tips']}>长按上图"发送朋友"/"保存手机"</p>*/}
                <p className={styles['save-tips']}>请手机截屏进行图片转发（能裁剪更佳）</p>
            </div>
        )
    }
    render() {
        return (
            <div className={styles['main-wrapper']} style={{height: this.state.wrapperHeight}}>
                {this.renderPosterBoard()}
                {this.renderOther()}
            </div>
        )
    }
}

ProductPoster = connect(mapStateToProps)(ProductPoster) || ProductPoster;
export default ProductPoster