import React from 'react'
import event from '../utils/event'

export default function reachBottomDecorator(WrappedComponent) {
    let timer;
    let handleScroll;
    return class reachBottomDecorator extends React.Component {
        constructor(props) {
            super(props);
            this.scrollFunc = this.scrollFunc.bind(this);
        }
        componentDidMount() {
            handleScroll = () => {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(this.scrollFunc, 50);
            };
            window.addEventListener('scroll', handleScroll, false);
        }
        componentWillUnmount() {
            window.removeEventListener('scroll', handleScroll);
            timer = null;
            handleScroll = null;
        }
        scrollFunc() {
            let clientHeight  = document.documentElement.clientHeight;
            let scrollHeight = document.body.scrollHeight;
            let scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;

            let distance = 50;
            if (scrollTop + clientHeight >= scrollHeight - distance) {
                this.loadMoreFn();
            }
        }
        loadMoreFn() {
            event.emit('reachBottom');
        }
        render() {
            return <WrappedComponent {...this.props} />
        }
    }
}