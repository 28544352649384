import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { getString, isEmptyObject, formatTime } from '../../utils/common'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'
import { ENV_ALIPAY, ENV_WEAPP } from '../../utils/compat'
import { Toast, List } from 'antd-mobile'
import BillsItem from '../../components/bills-item'

import './detail.scss'
import styles from './detail.module.scss'

const mapStateToProps = store => ({
    checkedAddressItem: store.address,
    userInfo: store.userInfo,
    appType: store.appType
});
let timer = null;

class OrderDetail extends React.Component {
    state = {
        addressList: [],
        item: {
            OrderDetail: []
        },
        wasTimeout: false,      // 交易是否超时
        hour: 0,
        minute: 0,
        second: 0,
        addDateStr: '',
        expireDateStr: '',
    }
    componentDidMount() {
        let _this = this;
        let addDate = '2020-08-01T01:42:48.572Z';   // 初始测试日期
        const t = 4 * 60 * 60 * 1000;

        if (this.props.location.hasOwnProperty('query')) {
            if (this.props.location.query.hasOwnProperty('item')) {
                let item = this.props.location.query.item;
                console.log(item);
                addDate = item.AddDate;
                this.setState({ item });
            }
        }

        function _formatTime() {
            const currentAddDate = new Date(addDate);
            const expireDate = new Date(currentAddDate.getTime() + t);

            _this.setState({
                addDateStr: formatTime(currentAddDate),
                expireDateStr: formatTime(expireDate)
            });
        }

        function timeCallback() {
            const now = new Date();

            const addDateTimeStamp = +new Date(addDate);
            const nowTimeStamp = +now;

            const deltaTimeStamp = nowTimeStamp - addDateTimeStamp;
            if (deltaTimeStamp > t) {
                if (timer) {
                    clearInterval(timer);
                    timer = null;
                }
                _this.setState({ wasTimeout: true });
                return;
            }

            const invertDeltalTimeStamp = t - deltaTimeStamp;
            _this.setState({
                hour: parseInt((invertDeltalTimeStamp % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minute: parseInt((invertDeltalTimeStamp % (1000 * 60 * 60)) / (1000 * 60)),
                second: parseInt((invertDeltalTimeStamp % (1000 * 60)) / 1000)
            });
        }

        _formatTime();
        timeCallback();
        timer = setInterval(timeCallback, 1000);

        this.loadAddressList();
    }
    componentWillUnmount() {
        if (timer) {
            clearInterval(timer);
            timer = null;
        }
    }
    async loadAddressList() {
        let res = await Fetch.get(api.getMemberAddressList + `?MemberId=${this.props.userInfo.Id}`);
        this.setState({
            addressList: res.data
        })
    }
    getOrderModel() {
        const { checkedAddressItem } = this.props;
        const { addressList, item } = this.state;

        let memberAddressId = isEmptyObject(checkedAddressItem) ? addressList[0].Id : checkedAddressItem.Id;
        let goodsDetailList = [...item.OrderDetail].map(item => ({
            GoodsId: item.GoodsId,
            GoodsSpecDetailGroupId: item.GoodsSpecDetailGroupId,
            GoodsPrice: item.TotalPrice,
            stepperVal: item.GoodsNum
        }));

        return {
            MemberAddressId: memberAddressId,
            OrderNo: item.OrderNo,
            GoodsRemark: item.GoodsRemark,
            GoodsDetailList: goodsDetailList,
            TotalMoney: item.OrderPrice,
            CouponsPrice: 0
        }
    }
    onDel() {
        Toast.info('删除订单成功', 1, () => {
            this.props.history.goBack();
        });
    }
    onSubmit() {
        switch (this.props.appType) {
            case ENV_WEAPP:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/orderV2/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
                break;
            case ENV_ALIPAY:
                window.my.navigateTo({
                    url: `/pages/order/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
                break;
            default:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/orderV2/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
        }
    }
    renderCountdownBar() {
        const { wasTimeout, hour, minute, second } = this.state;

        return (
            <div className={styles['countdown-bar']}>
                <h2 className={styles['countdown-bar-title']}>{wasTimeout ? '交易关闭' : '等待买家付款'}</h2>
                <p className={styles['countdown-bar-desc']}>
                    {
                        wasTimeout
                        ? '超时未付款，订单自动关闭'
                        : `订单将在${hour ? hour + '小时' : ''}${minute ? minute + '分' : ''}${minute ? second + '秒' : second ? second + '秒' : ''}后自动关闭，请尽快完成支付。`
                    }
                </p>
            </div>
        )
    }
    renderSelectedAddress() {
        const { addressList } = this.state;
        const { checkedAddressItem } = this.props;
        const addressItem = isEmptyObject(checkedAddressItem) ? addressList[0] : checkedAddressItem;

        return (
            <div className={styles['bill-address-wrapper']}>
                <div className={styles['bill-address-box']}>
                    <div className={styles['bill-selected-info-box']}>
                        <div className={styles['bill-selected-top']}>
                            <h2 className={styles['bill-selected-name']}>{addressItem.RealName}</h2>
                            <h2 className={styles['bill-selected-mobile']}>{addressItem.Mobile}</h2>
                        </div>
                        <div className={styles['bill-selected-address']}>
                            <span className={styles['bill-selected-address-text']}>{getString(addressItem.ProvinceName) + getString(addressItem.CityName) + getString(addressItem.PrefecsureName)}</span>
                            <span className={styles['bill-selected-address-text']}>{addressItem.DetailAddress}</span>
                        </div>
                    </div>
                </div>
                <div className={styles['border-bg']}>

                </div>
            </div>
        )
    }
    renderMainBox() {
        const { item } = this.state;

        return (
            <div className={styles['main-box']}>
                {
                    item.OrderDetail.map((order, index) => {
                        return (
                            <BillsItem
                                key={`order-detail-${index}`}
                                item={order}
                                className='order-detail-bills-item'
                                field={{
                                    goodsName: 'GoodsName',
                                    goodsPrice: 'TotalPrice'
                                }}>
                                <p className={styles['bills-item-right-text']}>x{order.GoodsNum}</p>
                            </BillsItem>
                        )
                    })
                }
                <List className='product-detail-normal-custom-list'>
                    <List.Item
                        className='product-detail-normal-custom-list-item'
                        extra={`￥${(item.RealPrice && (item.RealPrice !== item.OrderPrice)) ? item.RealPrice : item.OrderPrice}`}>
                        商品总价
                    </List.Item>
                </List>
                <List className='product-detail-normal-custom-list'>
                    <List.Item
                        className='product-detail-normal-custom-list-item'
                        extra={`￥0`}>
                        运费
                    </List.Item>
                </List>
                {
                    (item.UseIntegralPrice != 0) && (
                        <List className='product-detail-normal-custom-list'>
                            <List.Item
                                className='product-detail-normal-custom-list-item'
                                extra={`-￥${item.UseIntegralPrice}`}>
                                积分
                            </List.Item>
                        </List>
                    )
                }
                <div className='h_line' style={{ margin: '10px 0 5px' }}>

                </div>
                <List className='product-detail-price-custom-list'>
                    <List.Item
                        className='product-detail-price-custom-list-item'
                        extra={`￥${item.OrderPrice}`}>
                        实付款
                    </List.Item>
                </List>
            </div>
        )
    }
    renderSupplementBox() {
        const { item, addDateStr, expireDateStr } = this.state;

        return (
            <div className={styles['supplement-box']}>
                <List className='product-detail-price-custom-list'>
                    <List.Item
                        className='product-detail-supplement-custom-list-item'
                        extra={''}>
                        订单编号：{item.OrderNo}
                    </List.Item>
                </List>
                <List className='product-detail-price-custom-list'>
                    <List.Item
                        className='product-detail-supplement-custom-list-item'
                        extra={''}>
                        下单时间：{addDateStr}
                    </List.Item>
                </List>
                <List className='product-detail-price-custom-list'>
                    <List.Item
                        className='product-detail-supplement-custom-list-item'
                        extra={''}>
                        关闭时间：{expireDateStr}
                    </List.Item>
                </List>
            </div>
        )
    }
    renderBillBar() {
        const { item, wasTimeout } = this.state;

        return (
            <div className={styles['bill-bar--blank']}>
                <div className={styles['bill-bar']}>
                    <div className={
                        classNames(
                            styles['price-info-box'],
                            {
                                [styles['price-info-box-o0']]: wasTimeout
                            }
                        )
                    }>
                        <p className={styles['price-desc']}>实付款：</p>
                        <p className={styles['price-text']}>
                            <span className={styles['price-text-yuan']}>￥</span>
                            {item.OrderPrice}
                        </p>
                    </div>
                    {
                        wasTimeout
                        ? <button className={styles['del-button']} onClick={this.onDel.bind(this)}>删除订单</button>
                        : <button className={styles['submit-button']} onClick={this.onSubmit.bind(this)}>立即支付</button>
                    }
                </div>
            </div>
        )
    }
    render() {
        const { addressList } = this.state;

        return (
            <>
                {this.renderCountdownBar()}
                { addressList.length > 0 && this.renderSelectedAddress() }
                {this.renderMainBox()}
                {this.renderSupplementBox()}
                {this.renderBillBar()}
            </>
        )
    }
}

OrderDetail = connect(mapStateToProps)(OrderDetail) || OrderDetail;
export default OrderDetail