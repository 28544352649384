export const commentMap = {
    pic: 1,
    video: 2,
    newest: 3,
    praise: 4,
    normal: 5,
    bad: 6,
    attach: 7,
    all: 10
};
export const orderStatusMap = {
    init: 0,            // 初始
    success: 1,         // 支付成功
    shipped: 2,         // 已发货
    received: 3,        // 已收货
    verifying: 4,       // 审核中
    refunded: 5         // 已退款
};