import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { hasSameProps } from '../../utils/common'

import styles from './index.module.scss'

class OptionsBox extends React.Component {
    state = {
        optionsTag: []
    }
    onOptionTag(item) {
        const { optionsTag } = this.state;
        let samePidArray = optionsTag.filter(hasSameProps(item, 'Status'));
        if (samePidArray.length === 0) {
            optionsTag.push(item);
        } else {
            let index = optionsTag.findIndex( hasSameProps(item, 'GoodsSpecDetailId'));
            if (index !== -1) {
                optionsTag.splice(index, 1);
            } else {
                let pIndex = optionsTag.findIndex(hasSameProps(item, 'Status'));
                optionsTag[pIndex] = item;
            }
        }
        this.setState({ optionsTag });
        this.props.onOptionTag(optionsTag);
    }
    render() {
        const { optionsTag } = this.state;
        const { item } = this.props;
        const { GoodsGroup } = item;

        return (
            <div className={styles['options-box']}>
                {
                    GoodsGroup.map((item, index) => {
                        return (
                            <div key={`options-${index}`} className={styles['options-box-item']}>
                                <p className={styles['options-item-title']}>{item.GoodsSpecName}：</p>
                                <div className={styles['options-main']}>
                                    {
                                        item.SpecDetail.map((option, option_index) => {
                                            return (
                                                <div
                                                    key={`option-${option_index}`}
                                                    className={classNames(
                                                        styles['options-tag'],
                                                        {
                                                            [styles['options-tag--active']]: optionsTag.findIndex(hasSameProps(option, 'GoodsSpecDetailId')) !== -1
                                                        }
                                                    )}
                                                    onClick={this.onOptionTag.bind(this, option)}>
                                                    {option.GoodsSpecDetailName}
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

OptionsBox.defaultProps = {
    item: {}
};
OptionsBox.propTypes = {
    item: PropTypes.object,
    onOptionTag: PropTypes.func
};

export default OptionsBox
