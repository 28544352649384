import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'
import AtLoading from '../loading'

export default class AtActivityIndicator extends React.Component {
    render () {
        const { color, size, mode, content } = this.props;

        const rootClass = classNames(
            'at-activity-indicator',
            {
                'at-activity-indicator--center': mode === 'center'
            },
            this.props.className
        );

        return (
            <div className={rootClass}>
                <div className='at-activity-indicator__body'>
                    <AtLoading size={size} color={color} />
                </div>
                {content && (
                    <div className='at-activity-indicator__content'>{content}</div>
                )}
            </div>
        )
    }
}

AtActivityIndicator.defaultProps = {
    size: 0,
    mode: '',
    color: '',
    content: '',
    className: '',
}

AtActivityIndicator.propTypes = {
    size: PropTypes.number,
    mode: PropTypes.string,
    color: PropTypes.string,
    content: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}
