import { CHECKED_ADDRESS, DEL_ADDRESS } from '../constants/address'

export const checkedAddress = (item) => {
    return dispatch => {
        dispatch({
            type: CHECKED_ADDRESS,
            item
        });
    };
};

export const delAddress = () => {
    return dispatch => {
        dispatch({
            type: DEL_ADDRESS
        });
    }
};