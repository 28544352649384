import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

class EmptyBox extends React.Component {
    render() {
        const { emptyImage, emptyText, className, emptyImageClassName, emptyTextClassName } = this.props;

        return (
            <div className={classNames(styles['empty-box'], className)}>
                <img className={classNames(styles['empty-box-image'], emptyImageClassName)} src={emptyImage} alt=""/>
                <p className={classNames(styles['empty-box-text'], emptyTextClassName)}>{emptyText}</p>
            </div>
        );
    }
}

EmptyBox.defaultProps = {

};
EmptyBox.propTypes = {
    className: PropTypes.string,
    emptyImage: PropTypes.string.isRequired,
    emptyText: PropTypes.string.isRequired,
    emptyImageClassName: PropTypes.string,
    emptyTextClassName: PropTypes.string,
};

export default EmptyBox
