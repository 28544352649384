import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getVideoPosterUrl } from '../../utils/common'
import { Toast } from 'antd-mobile'

import './index.scss'
import cameraPng from '../../images/icons/camera.png'
import addSvg from '../../images/icons/add.svg'
import playPng from '../../images/icons/play.png'
import switchPng from '../../images/icons/switch.png'

const prefixCls = 'upload-picker';
const ACCEPT_IMAGE = 'image/*';
const ACCEPT_VIDEO = 'video/*';
const ACCEPT_BOTH = 'video/* image/*';

function noop() {}

class UploadPicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            accept: ACCEPT_BOTH
        };

        this.fileSelectorInput = null;
    }
    getOrientation = (file, callback) => {
        const reader = new FileReader();
        reader.onload = e => {
            const view = new DataView(e.target.result);
            if (view.getUint16(0, false) !== 0xffd8) {
                return callback(-2);
            }
            const length = view.byteLength;
            let offset = 2;
            while (offset < length) {
                const marker = view.getUint16(offset, false);
                offset += 2;
                if (marker === 0xffe1) {
                    const tmp = view.getUint32((offset += 2), false);
                    if (tmp !== 0x45786966) {
                        return callback(-1);
                    }
                    const little = view.getUint16((offset += 6), false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    const tags = view.getUint16(offset, little);
                    offset += 2;
                    for (let i = 0; i < tags; i++) {
                        if (view.getUint16(offset + i * 12, little) === 0x0112) {
                            return callback(view.getUint16(offset + i * 12 + 8, little));
                        }
                    }
                } else if ((marker & 0xff00) !== 0xff00) {
                    break;
                } else {
                    offset += view.getUint16(offset, false);
                }
            }
            return callback(-1);
        };
        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    }
    getRotation = (orientation = 1) => {
        let imgRotation = 0;
        switch (orientation) {
            case 3:
                imgRotation = 180;
                break;
            case 6:
                imgRotation = 90;
                break;
            case 8:
                imgRotation = 270;
                break;
            default:
        }
        return imgRotation;
    }
    onSwitchType(e) {
        e.stopPropagation();
        const { accept } = this.state;

        let _accept;
        switch (accept) {
            case ACCEPT_IMAGE:
                _accept = ACCEPT_VIDEO;
                break;
            case ACCEPT_VIDEO:
                _accept = ACCEPT_BOTH;
                break;
            case ACCEPT_BOTH:
                _accept = ACCEPT_IMAGE;
                break;
            default:
                _accept = ACCEPT_IMAGE;
        }

        this.setState({
            accept: _accept
        });
    }
    removeItem = (index) => {
        const newItems = [];
        const { files = [] } = this.props;
        files.forEach((file, idx) => {
            if (index !== idx) {
                newItems.push(file);
            }
        });
        if (this.props.onChange) {
            this.props.onChange(newItems, 'remove', index);
        }
    }
    addItem = (items) => {
        const { files = [] } = this.props;
        const newItems = files.concat(items);
        if (this.props.onChange) {
            this.props.onChange(newItems, 'add');
        }
    }
    onFileChange = () => {
        const fileSelectorEl = this.fileSelectorInput;
        if (fileSelectorEl && fileSelectorEl.files && fileSelectorEl.files.length) {
            const files = fileSelectorEl.files;

            const itemParsePromiseList = [];
            for (let i = 0; i < files.length; i++) {
                let fileSize = files[i].size / 1024;
                if (fileSize > 1024 * 10) {
                    Toast.info('上传文件过大', 1);
                    break;
                }
                itemParsePromiseList.push(this.parseFile(files[i], i));
            }
            Promise.all(itemParsePromiseList)
                .then(items => this.addItem(items))
                .catch(
                    error => {
                        if (this.props.onFail) {
                            this.props.onFail(error);
                        }
                    }
                )
        }
        if (fileSelectorEl) {
            fileSelectorEl.value = '';
        }
    }
    parseFile = (file, index) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = async e => {
                const dataURL = e.target.result;
                if (!dataURL) {
                    reject(`Fail to get the ${index} image`);
                    return;
                }

                let posterUrl = dataURL;
                // 视频处理
                // 截取视频第一帧作为封面
                if (file.type.indexOf("video") > -1) {
                    posterUrl = await getVideoPosterUrl(dataURL);
                }

                let orientation = 1;
                this.getOrientation(file, res => {
                    if (res > 0) {
                        orientation = res;
                    }
                    resolve({
                        url: dataURL,       // 传给后台的url参数
                        posterUrl,          // 前端展示用的url参数
                        orientation,
                        file
                    })
                });
            }
            reader.readAsDataURL(file);
        })
    }
    onItemClick = (index) => {
        if (this.props.onItemClick) {
            this.props.onItemClick(index, this.props.files);
        }
    }
    render() {
        const { files, multiple, capture, selectable } = this.props;
        const { accept } = this.state;

        const itemList = [];
        let count = parseInt('' + this.props.length, 10);
        if (count <= 0) {
            count = 4;
        }

        files.forEach((file, index) => {
            const imgStyle = {
                backgroundImage: `url("${file.posterUrl}")`,
                transform: `rotate(${this.getRotation(file.orientation)}deg)`,
            };

            itemList.push(
                <div key={`file-${index}`} className={`${prefixCls}-flexbox-item`}>
                    <div className={`${prefixCls}-item`}>
                        <div
                            className={`${prefixCls}-item-remove`}
                            onClick={() => {
                                this.removeItem(index)
                            }}>

                        </div>
                        <div
                            className={`${prefixCls}-item-content`}
                            style={imgStyle}
                            onClick={() => {
                                this.onItemClick(index);
                            }}>
                            {
                                (file.url.indexOf("video") > -1) && (
                                    <img className={`${prefixCls}-play-btn`} src={playPng} alt="" />
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        });

        const selectEl = (
            <div key='select' className={`${prefixCls}-flexbox-item`}>
                <div className={`${prefixCls}-item ${prefixCls}-upload-btn`}>
                    <img
                        className='arc-img'
                        src={switchPng}
                        alt=""
                        onClick={this.onSwitchType.bind(this)}/>
                    <input
                        ref={ (input) => { if (input) { this.fileSelectorInput = input; } } }
                        type='file'
                        accept={accept}
                        multiple={multiple}
                        capture={capture}
                        onChange={() => {
                            this.onFileChange();
                        }} />
                    <img src={ (accept === ACCEPT_BOTH) ? addSvg : cameraPng } alt="" />
                    {
                        (accept === ACCEPT_IMAGE) && <p>拍照</p>
                    }
                    {
                        (accept === ACCEPT_VIDEO) && <p>录像</p>
                    }
                    {
                        (accept === ACCEPT_BOTH) && <p>选择文件</p>
                    }
                </div>
            </div>
        );

        let allEl = selectable ? itemList.concat([selectEl]) : itemList;
        const length = allEl.length;
        // flex布局需要补全数目才能显示预期样子
        // 以下为补充空白项目的代码
        if (length !== 0 && length % count !== 0) {
            const blankCount = count - length % count;
            const fillBlankEl = [];
            for (let i = 0; i < blankCount; i++) {
                fillBlankEl.push(
                    <div key={`blank-${i}`} className={`${prefixCls}-flexbox-item`}>

                    </div>
                );
            }
            allEl = allEl.concat(fillBlankEl);
        }

        const flexEl = [];
        // 每行排布item的规则
        for (let i = 0; i < allEl.length / count; i++) {
            const rowEl = allEl.slice(i * count, i * count + count);
            flexEl.push(rowEl);
        }
        const renderEl = flexEl.map((item, index) => (
            <div key={`flex-${index}`} className={`${prefixCls}-flexbox`}>
                {item}
            </div>
        ));

        return (
            <div className={`${prefixCls}`}>
                {renderEl}
            </div>
        );
    }
}

UploadPicker.defaultProps = {
    files: [],
    length: 4,
    multiple: false,
    capture: false,
    selectable: true,
    onItemClick: noop,
    onChange: noop,
    onFail: noop,
};
UploadPicker.propTypes = {
    length: PropTypes.number,
    files: PropTypes.array,
    multiple: PropTypes.bool,
    capture: PropTypes.bool,
    selectable: PropTypes.bool,
    onItemClick: PropTypes.func,
    onChange: PropTypes.func,
    onFail: PropTypes.func,
};

export default UploadPicker
