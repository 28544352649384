import React from 'react'
import classNames from 'classnames'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'
import event from '../../utils/event'
import { commentMap } from '../../utils/mapping-table'
import { Toast } from 'antd-mobile'
import CommentItem from '../../components/comment-item'
import PreviewMask from '../../components/preview-mask'
import AtLoadMore from '../../components/load-more'
import CustomLoadingIcon from '../../components/custom-loading-icon'
import EmptyBox from '../../components/empty-box'
import LoadMoreDecorator from '../../hocs/loadMoreDecorator'
import ReachBottomDecorator from '../../hocs/reachBottomDecorator'

import styles from './list.module.scss'
import normalEmpty from '../../images/common/normal-empty.png'

class CommentList extends React.Component {
    state = {
        activeCateId: commentMap.all,
        goodsId: '',
        likes: [],
        cateList: [],
        commentList: [],
        CommentCount: 0,
        GoodCommentRate: 0,
        activeType: '',
        activeUrl: '',
    }
    componentDidMount() {
        event.on('reachBottom', async () => {
            const { hasMore, activeCateId } = this.state;
            if (!hasMore) return;
            await this.nextPage();
            this.loadCommentList(activeCateId, this.state.pageIndex);
        });
        const { goodsId } = this.props.location.query;
        this.setState({
            goodsId
        }, () => {
            this.loadCateList();
            this.loadCommentList(this.state.activeCateId, this.state.pageIndex);
        });
    }
    componentWillUnmount() {
        event.removeAllListeners();
        this.postCommentLike();
    }
    async loadCateList() {
        let res = await Fetch.get(api.getGoodsCommentCount + `?GoodsId=${this.state.goodsId}`);
        this.setState({
            cateList: res.data
        })
    }
    async loadCommentList(activeCateId, pageIndex) {
        let { data } = await Fetch.get(api.getGoodsCommentList + `?GoodsId=${this.state.goodsId}&Status=${activeCateId}&PageIndex=${pageIndex}`);
        let { commentList: prevList }  = this.state,
            { CommentList: curList, TotalPage, CommentCount, GoodCommentRate } = data,
            finalList = [];

        if (TotalPage !== 0 && CommentCount === 0) {
            this.noMore();
            return;
        }
        if (this.state.initLoad) {
            this.setState({
                CommentCount,
                GoodCommentRate,
                commentList: curList
            });
            return;
        }
        if (pageIndex <= TotalPage) {
            finalList = [...prevList, ...curList];
        } else {
            finalList = prevList;
            this.noMore();
        }
        this.setState({
            CommentCount,
            GoodCommentRate,
            commentList: finalList
        });
    }
    async onChangeCate(cateId) {
        await this.initLoadMore();
        this.setState({
            activeCateId: cateId
        });
        Toast.loading(<CustomLoadingIcon />, 15);
        await this.loadCommentList(cateId, this.state.pageIndex);
        Toast.hide();
    }
    onPreview(url, type) {
        let activeType = type === 1
            ? "image"
            : type === 2
                ? "video"
                : "image";

        this.setState({
            activeType,
            activeUrl: url,
        });
    }
    onClosePreviewMask() {
        this.setState({
            activeUrl: '',
        });
    }
    postCommentLike() {
        const { likes } = this.state;
        for (let likeCommentId of likes) {
            Fetch.post(api.createGoodsCommentLikes, { CommentId: likeCommentId });
        }
    }
    onLike (item, isLikeOn) {
        const { likes } = this.state;
        let _likes = [...likes];
        let isInclude = _likes.includes(item.Id);

        if (isLikeOn) {
            if (!isInclude) {
                _likes.push(item.Id);
            }
        } else {
            if (isInclude) {
                _likes.splice( _likes.findIndex(el => el.Id === item.Id), 1);
            }
        }
        this.setState({
            likes: _likes
        })
    }
    renderTopInfo() {
        const { GoodCommentRate, CommentCount } = this.state;

        return (
            <div className={styles['info-box']}>
                <p className={styles['info-box-left']}>共{CommentCount}条评论</p>
                <p className={styles['info-box-right']}>好评率{GoodCommentRate}%</p>
            </div>
        )
    }
    renderCateList() {
        const { cateList, activeCateId } = this.state;

        return (
            <div className={styles['comment-cate-list']}>
                {
                    cateList.map((item, index) => {
                        return (
                            <div
                                key={`comment-cate-list-${index}`}
                                className={classNames(
                                    'comment-cate-tag',
                                    {
                                        'comment-cate-tag--active': item.Status === activeCateId
                                    }
                                )}
                                onClick={this.onChangeCate.bind(this, item.Status)}>
                                {item.CommentTypeName}
                                {item.GoodsCommentCount ? `(${item.GoodsCommentCount})` : ''}
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    renderCommentList() {
        const { commentList } = this.state;

        return (
            <div className={styles['comment-list']}>
                {
                    commentList.map((item, index) => {
                        return (
                            <CommentItem
                                key={`comment-list-${index}`}
                                item={item}
                                onLike={this.onLike.bind(this)}
                                onPreview={this.onPreview.bind(this)} />
                        )
                    })
                }
            </div>
        )
    }
    renderLoadMore() {
        const { loadMoreStatus, initLoad } = this.state;

        return (
            <div className='load-more-wrapper' style={{ visibility: initLoad ? 'hidden' : 'visible' }}>
                <AtLoadMore status={loadMoreStatus} />
            </div>
        )
    }
    renderEmptyBox() {
        return (
            <EmptyBox
                emptyImageClassName={styles['empty-image']}
                emptyImage={normalEmpty}
                emptyText='暂无评论' />
        )
    }
    renderPreviewMask() {
        const { activeType, activeUrl } = this.state;

        return (
            <PreviewMask
                dataType={activeType}
                dataUrl={activeUrl}
                onClose={this.onClosePreviewMask.bind(this)} />
        )
    }
    render() {
        const { commentList } = this.state;

        return (
            <>
                {this.renderTopInfo()}
                {this.renderCateList()}
                { commentList.length ? this.renderCommentList() : this.renderEmptyBox()}
                {this.renderLoadMore()}
                {this.renderPreviewMask()}
            </>
        )
    }
}

CommentList = LoadMoreDecorator(CommentList) || CommentList;
CommentList = ReachBottomDecorator(CommentList) || CommentList;
export default CommentList