import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'

class SkeletonProductItem extends React.Component {
    render() {
        return (
            <div className='skeleton-product-item'>
                <div className='main-block'>

                </div>
                <div className='main-group'>
                    <div className='short-line-block' style={{marginBottom: '6px'}}>

                    </div>
                    <div className='short-line-block'>

                    </div>
                    <div className='long-line-block'>

                    </div>
                </div>
            </div>
        );
    }
}

SkeletonProductItem.defaultProps = {

};
SkeletonProductItem.propTypes = {

};

export default SkeletonProductItem
