import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import likeOff from '../../images/icons/like-off.png'
import likeOn from '../../images/icons/like-on.png'

class Like extends React.Component {
    render() {
        const { isLikeOn } = this.props;

        return (
            <div className={styles['like-box']}>
                <img
                    className={classNames(
                        styles['like-img'],
                        {
                            'bounceIn': !isLikeOn,
                            'scaleOut': isLikeOn
                        }
                    )}
                    src={likeOff}
                    alt="" />
                <img
                    className={classNames(
                        styles['like-img'],
                        {
                            'bounceIn': isLikeOn,
                            'scaleOut': !isLikeOn
                        }
                    )}
                    src={likeOn}
                    alt=""
                    style={{display: isLikeOn ? 'block' : 'none'}} />
            </div>
        );
    }
}

Like.defaultProps = {

};
Like.propTypes = {
    isLikeOn: PropTypes.bool
};

export default Like
