import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'
import AtActivityIndicator from '../activity-indicator'

export default class AtLoadMore extends React.Component {
    onClick () {
        this.props.onClick(...arguments)
    }

    render () {
        const {
            className,
            customStyle,
            loadingText,
            moreText,
            status,
            noMoreTextStyle,
            noMoreText
        } = this.props;

        let component = null;
        if (status === 'loading') {
            component = <AtActivityIndicator mode='center' content={loadingText} />
        } else if (status === 'more') {
            component = (
                <div className='at-load-more__cnt'>
                    {moreText}
                </div>
            )
        } else {
            component = <div
                className='at-load-more__text-box'
                style={noMoreTextStyle}
            >
                <span className='at-load-more__line'></span>
                <span className='at-load-more__tip'>{noMoreText}</span>
                <span className='at-load-more__line'></span>
            </div>
        }

        return (
            <div
                className={classNames('at-load-more', className)}
                style={customStyle}
            >
                {component}
            </div>
        )
    }
}

AtLoadMore.defaultProps = {
    customStyle: {},
    className: '',
    noMoreTextStyle: {},
    status: 'more',
    loadingText: '加载中',
    moreText: '查看更多',
    noMoreText: '这是我的底线哦',
    onClick: () => {},
}

AtLoadMore.propTypes = {
    customStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    className: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string
    ]),
    noMoreTextStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    status: PropTypes.oneOf(['more', 'loading', 'noMore']),
    loadingText: PropTypes.string,
    moreText: PropTypes.string,
    noMoreText: PropTypes.string,
    onClick: PropTypes.func,
}
