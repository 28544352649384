// const apiUrl = 'https://testapi.caier8.com';
// const apiUrl = 'http://192.168.1.131:8019';
// const apiUrl = 'http://47.112.206.255:8006';
const apiUrl = 'https://api.caier8.com';

const apiInterface = {
    // Common
    getBannerList: '/Common/GetBannerList',                                             // 轮播
    // City
    getCityListById: '/City/GetCityListById',                                           // 区域列表
    getMemberAddressList: '/City/GetMemberAddressList',                                 // 获取地址列表
    getMemberAddressDefault: '/City/GetMemberAddressDefault',                           // 是否有默认地址
    editMemberAddressInfo: '/City/EditMemberAddressInfo',                               // 地址编辑
    deleteMemberAddressById: '/City/DeleteMemberAddressById',                           // 删除地址
    // Goods
    getCategoryList: '/Goods/GetCategoryList',                                          // 商品类别
    getAllGoodsIndexList: '/Goods/GetAllGoodsIndexList',                                // 获取首页商品列表
    getGoodsSpecDetailGroupList: '/Goods/GetGoodsSpecDetailGroupList',                  // 加入购物车时获取规格和规格明细
    getGoodsSpecDetailGroupInfo: '/Goods/GetGoodsSpecDetailGroupInfo',                  // 获取规格分组明细
    getGoodsDetail: '/Goods/GetGoodsDetail',                                            // 商品详情
    getGoodsLikeList: '/Goods/GetGoodsLikeList',                                        // 可能喜欢
    getImageCode: '/Goods/GetImageCode',                                                // 分享二维码
    // GoodsComment
    getGoodsCommentList: '/GoodsComment/GetGoodsCommentList',                           // 商品评论列表
    createGoodsComment: '/GoodsComment/CreateGoodsComment',                             // 发表评价
    createGoodsCommentLikes: '/GoodsComment/CreateGoodsCommentLikes',                   // 新增点赞数
    getGoodsCommentCount: '/GoodsComment/GetGoodsCommentCount',                         // 评论分类列表
    uploadGoodsCommentFile: '/GoodsComment/UploadGoodsCommentFile',                     // 上传图片视频
    // GoodsOrder
    getGoodsBuyRateList: '/GoodsOrder/GetGoodsBuyRateList',                             // 获取伪造数据
    createGoodsOrder: '/GoodsOrder/CreateGoodsOrder',                                   // 下单
    getGoodsOrderByMemberId: '/GoodsOrder/GetGoodsOrderByMemberId',                     // 获取用户的订单
    getLogisticsList: '/GoodsOrder/GetLogisticsList',                                   // 物流信息
    getIntegralAlgorithm: '/GoodsOrder/GetIntegralAlgorithm',                           // 获取积分兑换比率
    // Member
    getMemberById: '/Member/GetMemberById',                                             // 获取用户信息
};

export default apiInterface;
export {
    apiUrl
};