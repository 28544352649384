import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'
import listPng from '../../images/icons/list.png'

class FabOrderList extends React.Component {
    render() {
        return (
            <Link to='/orderList' className='fab-box' style={{marginTop: '13px'}}>
                <div className={styles['fab-order-list-box']}>
                    <img className={styles['fab-order-list']} src={listPng} alt="" />
                    <span className={styles['fab-order-list-text']}>我的订单</span>
                </div>
            </Link>
        );
    }
}

FabOrderList.defaultProps = {

};
FabOrderList.propTypes = {

};

export default FabOrderList
