const qs = require('qs');
const { apiUrl } = require('./api');

export enum ContentType {
    json = 'application/json;charset=UTF-8',
    form = 'application/x-www-form-urlencoded;charset=UTF-8'
}
export enum HttpMethod {
    get = 'GET',
    post = 'POST'
}
export interface IHeader {
    'Content-Type'?: string
    'X-Requested-With': string
    token?: string
    [propName: string]: any
}
export interface IReqConfig {
    body?: any
    method?: string
    headers?: IHeader
    token?: string
    'Content-Type'?: string
}

const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
const parseRes = async (res: Response) => {
    const contentType = res.headers.get('Content-Type');
    if (contentType) {
        if (contentType.indexOf('json') > -1) {
            return await res.json();
        }
        if (contentType.indexOf('text') > -1) {
            return await res.text();
        }
        if (contentType.indexOf('form') > -1) {
            return await res.formData();
        }
        if (contentType.indexOf('video') > -1) {
            return await res.blob();
        }
    }
    return await res.text();
};
const handleRes = async (res: Response) => {
    const parsedRes = await parseRes(res);
    if (res.ok) {
        return parsedRes;
    }
    // 请求失败，返回解析之后的失败的数据
    const error = parsedRes;
    throw error;
};
const $req = async (url: string, config?: IReqConfig) => {
    let promise: Response;
    let contentType: string;
    if (config && config['Content-Type'] !== undefined) {
        contentType = config['Content-Type'];
    } else if (config && config.method === HttpMethod.post) {
        contentType = ContentType.form;
    } else {
        contentType = ContentType.json;
    }
    const reqUrl = baseUrl + url;
    const headers: Headers = new Headers({
        token: (config && config.token === undefined) ? sessionStorage.token : (config && config.token),
        'Content-Type': contentType
    } as IHeader);
    // get
    if (config && (!config.method || config.method === HttpMethod.get)) {
        promise = await fetch(reqUrl, {
            headers
        })
    }
    // post
    else if (config && (config.method === HttpMethod.post)) {
        promise = await fetch(reqUrl, {
            body: qs.stringify(config && config.body),
            headers,
            method: HttpMethod.post
        })
    } else {
        promise = await fetch(reqUrl, {
            body: JSON.stringify(config && config.body),
            headers,
            method: config && config.method
        })
    }
    return handleRes(promise);
};
const get = async (url: string) => {
    return await $req(url);
};
const post = async (url: string, data: any) => {
    return await $req(url, {
        body: data,
        method: HttpMethod.post
    });
};
export default {
    get,
    post
};