import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Modal, Stepper, Toast } from 'antd-mobile'
import OptionsBox from '../options-box'

import './index.scss'
import styles from './index.module.scss'
import close from '../../images/icons/close.png'

function InfoBox({ item }) {
    return (
        <div className={styles['info-box']}>
            <div
                className={styles['pro-img']}
                style={{ backgroundImage: `url(${item.GoodsImageUrl})` }}>

            </div>
            <div className={styles['info-box-main']}>
                <h2 className={styles['info-title']}>{item.GoodsName}</h2>
                <p className={styles['info-price']}>
                    <span className={styles['info-price-yuan']}>￥</span>
                    {
                        item.GoodsPrice
                        ? item.GoodsPrice
                        : `${item.MinPrice}~${item.MaxPrice}`
                    }
                </p>
                <div className={styles['count-box']}>
                    <p className={styles['count-box-text']} style={{marginRight: '12px'}}>库存：{item.GoodsNum ? `${item.GoodsNum}件` : '暂无库存'}</p>
                    <p className={styles['count-box-text']}>销量：{item.SalesNum}</p>
                </div>
            </div>
        </div>
    )
}
let optionsTag = [];
class CartPopup extends React.Component {
    state = {
        stepperVal: 1
    }
    onOptionTag(_optionsTag) {
        optionsTag = _optionsTag;
        this.props.onOptionTag(_optionsTag);
    }
    onStepperChange(val) {
        if (optionsTag.length !== this.props.item.GoodsGroup.length) {
            Toast.info('请选择完整规格', 1);
        } else {
            this.setState({ stepperVal: val });
        }
    }
    onClose() {
        optionsTag = [];
        this.setState({ stepperVal: 1 });
        this.props.onClose();
    }
    onConfirm() {
        if (!this.props.item.GoodsNum) {
            Toast.info('该商品暂无库存', 1);
            return;
        }
        this.props.onConfirm(optionsTag, this.state.stepperVal);
        setTimeout(() => this.setState({ stepperVal: 1 }), 500);
        optionsTag = [];
    }
    renderStepper() {
        const { stepperVal } = this.state;
        const { item } = this.props;

        return (
            <div className={styles['stepper-section']}>
                <p className={styles['stepper-section-title']}>购买数量：</p>
                <Stepper
                    className='cart-stepper'
                    showNumber
                    min={1}
                    max={item.GoodsNum}
                    value={Math.min(item.GoodsNum, stepperVal)}
                    onChange={this.onStepperChange.bind(this)} />
            </div>
        )
    }
    renderSubmit() {
        return (
            <div className={styles['submit-box']}>
                <button className={styles['submit-button']} onClick={this.onConfirm.bind(this)}>确定</button>
            </div>
        )
    }
    render() {
        const { show, item } = this.props;

        return (
            <Modal
                popup
                className='cart-popup'
                animationType='slide-up'
                visible={show}
                onClose={this.onClose.bind(this)}>
                <img className={styles.close} src={close} alt="" onClick={this.onClose.bind(this)}/>
                <div className={styles['my-wrapper']}>
                    <InfoBox {...this.props} />
                    <div className={styles['scroll-view']}>
                        <OptionsBox
                            item={item}
                            onOptionTag={this.onOptionTag.bind(this)} />
                        {this.renderStepper()}
                    </div>
                </div>
                {this.renderSubmit()}
            </Modal>
        );
    }
}

CartPopup.defaultProps = {
    item: {}
};
CartPopup.propTypes = {
    show: PropTypes.bool,
    item: PropTypes.object,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func,
    onOptionTag: PropTypes.func
};

export default CartPopup
