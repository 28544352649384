import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import homePng from '../../images/icons/home.png'

class FabHome extends React.Component {
    render() {
        const { onClick } = this.props;

        return (
            <div className='fab-box' onClick={onClick}>
                <div className={styles['fab-home-box']}>
                    <img className={styles['fab-home']} src={homePng} alt="" />
                    <span className={styles['fab-home-text']}>返回首页</span>
                </div>
            </div>
        );
    }
}

FabHome.defaultProps = {

};
FabHome.propTypes = {
    onClick: PropTypes.func
};

export default FabHome
