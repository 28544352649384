import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import closePng2 from '../../images/icons/close2.png'

function getImageRealSize(dataUrl) {
    let obj = {};
    let image = new Image();
    image.src = dataUrl;
    obj.width = image.naturalWidth;
    obj.height = image.naturalHeight;
    image = null;

    return obj;
}
function normalizeSize(imageSize) {
    let obj = {};
    if (imageSize.width * 2 > window.innerWidth) {
        obj.width = '100%';
        obj.height = 'auto';
    } else {
        obj.width = (imageSize.width * 2) + 'px';
        obj.height = (imageSize.height * 2) + 'px';
    }
    return obj;
}

class PreviewMask extends React.Component {
    render() {
        const { dataType, dataUrl, onClose } = this.props;

        let contentEl = null;

        if (dataType === 'image') {
            const imageSize = dataUrl && getImageRealSize(dataUrl);
            const _normalizeSize = normalizeSize(imageSize);

            contentEl = (
                <div
                    className={styles['preview-img']}
                    style={{
                        backgroundImage: `url(${dataUrl})`,
                        backgroundSize: `${_normalizeSize.width} ${_normalizeSize.height}`
                    }}>

                </div>
            );
        } else if (dataType === 'video') {
            contentEl = (
                <video
                    className={styles['preview-video']}
                    src={dataUrl}
                    controls='controls'
                    preload='auto'
                    playsinline
                    autoPlay
                    muted>

                </video>
            );
        }

        return (
            <div
                className={styles['preview-img-mask']}
                style={{
                    display: dataUrl ? 'block' : 'none'
                }}
                onClick={onClose}>
                <img className={styles['preview-close']} src={closePng2} alt="" />
                {contentEl}
            </div>
        );
    }
}

PreviewMask.defaultProps = {
    dataType: 'image'   // 'image' / 'video'
};
PreviewMask.propTypes = {
    dataType: PropTypes.string,
    dataUrl: PropTypes.string,
    onClose: PropTypes.func,
};

export default PreviewMask
