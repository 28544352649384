import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

const startPos = '100%';
const middlePos = 0;
const endPos = '-100%';
let timer1, timer2, timer3;
class FakeNews extends React.Component {
    state = {
        ty: startPos,
        isTransition: true,
        showItem: {
            AvatarUrl: '',
            Remark: ''
        },
    }
    componentDidMount() {
        this.fakeAnimation()
    }
    async componentWillUnmount() {
        for (let i = 1; i <= 3; i++) {
            await clearTimeout('timer' + i);
        }
    }
    async fakeAnimation() {
        if (timer1) {
            clearTimeout(timer1);
        }
        if (timer2) {
            clearTimeout(timer2);
        }
        const { list } = this.props;
        function timeoutCallback() {
            this.setState({ ty: middlePos, isTransition: true });
            timer1 = setTimeout(() => {
                this.setState({ ty: endPos });
            }, 3000);
            return new Promise(resolve => {
                timer2 = setTimeout(() => {
                    this.setState({ ty: startPos, isTransition: false }, () => resolve());
                }, 3500);
            });
        }
        function startTimeout(i) {
            if (timer3) {
                clearTimeout(timer3);
            }
            this.setState({
                showItem: list[i]
            });
            return new Promise(resolve => {
                timer3 = setTimeout(() => {
                    timeoutCallback.bind(this)().then(() => resolve());
                }, 6000);
            });
        }
        for (let i = 0; i < list.length; i++) {
            await startTimeout.bind(this)(i);
        }
    }
    render() {
        const { ty, isTransition, showItem } = this.state;

        return (
            <div className={styles['fake-news-box']}>
                <div
                    className={styles['fake-news-main']}
                    style={{
                        transform: `translate3d(0,${ty},0)`,
                        transition: isTransition ? 'transform 0.25s ease-in-out' : 'none'
                    }}>
                    <img className={styles['fake-avatar']} src={showItem.AvatarUrl} alt=""/>
                    <p className={styles['fake-message']}>{showItem.Remark}</p>
                </div>
            </div>
        );
    }
}

FakeNews.defaultProps = {
    list: []
};
FakeNews.propTypes = {
    list: PropTypes.array
};

export default FakeNews
