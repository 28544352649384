import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'
import defaultStarOn from './star_on.png'
import defaultStarOff from './star_off.png'

class Star extends React.Component {
    state = {
        stars: []
    }
    componentWillMount() {
        let { count, value } = this.props;
        this.setStateStars(count, value);
    }
    componentWillReceiveProps (nextProps) {
        let { count, value } = this.props;
        if (count !== nextProps.count || value !== nextProps.value) {
            this.setStateStars(nextProps.count, nextProps.value);
        }
    }
    setStateStars(count, value) {
        typeof count === 'string' && (count = parseInt(count));
        let stars = Array.from({
            length: count
        }, function (_, index) {
            return index < value;
        });
        this.setState({ stars });
    }
    onSelect = (index) => {
        if (!this.props.readOnly) {
            this.props.onChange && this.props.onChange(index + 1);
        }
    }
    render() {
        const { readOnly, size, margin, starOn, starOff } = this.props;
        const { stars } = this.state;

        return (
            <div className={classNames('star-box', this.props.className)}>
                {
                    stars.map((star, index) => {
                        return (
                            <img
                                className={classNames(
                                    { 'star-on': star },
                                    { 'star-off': !star }
                                )}
                                key={index}
                                src={star ? starOn : starOff}
                                alt=''
                                style={{
                                    width: `${size}px`,
                                    height: `${size}px`,
                                    marginRight: `${margin}px`,
                                    display: readOnly ? !star ? 'none' : 'block' : 'block'
                                }}
                                onClick={this.onSelect.bind(this, index)} />
                        )
                    })
                }
            </div>
        );
    }
}

Star.defaultProps = {
    size: 10,
    margin: 4,
    count: 5,
    value: 0,
    starOn: defaultStarOn,
    starOff: defaultStarOff
};
Star.propTypes = {
    readOnly: PropTypes.bool,
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    margin: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    count: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    starOn: PropTypes.string,
    starOff: PropTypes.string,
    onChange: PropTypes.func
};

export default Star
