import React from 'react'
import { connect } from 'react-redux'
import { updateUserInfo } from '../actions/userInfo'
import { delAddress } from '../actions/address'
import { delAll } from '../actions/carts'
import { updateAppType } from '../actions/appType'
import { getAllUrlParam } from '../utils/common'
import Fetch from '../utils/fetch-config'
import api from '../utils/api'

const mapStateToProps = store => ({
    userInfo: store.userInfo
});
const mapDispatchToProps = dispatch => ({
    delAddress: () => {
        dispatch(delAddress());
    },
    delAll: () => {
        dispatch(delAll());
    },
    updateUserInfo: userInfo => {
        dispatch(updateUserInfo(userInfo));
    },
    updateAppType: appType => {
        dispatch(updateAppType(appType));
    }
});
export default function entryDecorator(WrappedComponent) {
    class entryDecorator extends React.Component {
        constructor(props) {
            super(props);
        }
        componentDidMount() {
            let userId = this.props.userInfo && this.props.userInfo.Id;
            let { memberId: paramMemberId, appType } = getAllUrlParam(this.props.location.search.slice(1));

            if (appType) {
                this.props.updateAppType(Number(appType));
            }
            if (paramMemberId && !userId) {
                this.loadUserInfo(paramMemberId)
                    .then(userInfo => {
                        this.props.updateUserInfo(userInfo);
                    });
            } else if (paramMemberId && (userId.toLowerCase() !== paramMemberId.toLowerCase())) {
                this.loadUserInfo(paramMemberId)
                    .then(userInfo => {
                        this.props.updateUserInfo(userInfo);
                        this.props.delAddress();
                        this.props.delAll();
                    });
            }
        }
        async loadUserInfo(memberId) {
            let res = await Fetch.get(api.getMemberById + `?MemberId=${memberId}`);
            return res.data;
        }
        render() {
            return <WrappedComponent entryData={{ userInfo: this.props.userInfo }} {...this.props} />
        }
    }
    entryDecorator = connect(mapStateToProps, mapDispatchToProps)(entryDecorator) || entryDecorator;
    return entryDecorator;
}