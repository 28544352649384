import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import cart from '../../images/icons/shop-cart.png'

let isTransition = false;
class ProductItem extends React.Component {
    state = {
        imageLoaded: false
    }
    onImageLoad() {
        this.setState({ imageLoaded: true });
    }
    onProductItem(item, e) {
        let targetHtml = e.target.outerHTML;
        // 阻止捕获到点击购物车
        if (targetHtml.indexOf('cart') !== -1) return;
        console.log('product-item click');
        this.props.onProductItem(item);
    }
    onCart(item, e) {
        if (this.props.startAnimation && isTransition) return;
        console.log('cart click');
        isTransition = true;
        let cartRect = e.target.getBoundingClientRect();
        this.props.onCart(item, cartRect);
    }
    onCartProductImgTransitionEnd() {
        isTransition = false;
        this.props.onCartProductImgTransitionEnd();
    }
    render() {
        const { imageLoaded } = this.state;
        const { item, activeCartId, startAnimation, cartAnimationModel } = this.props;

        return (
            <div className={styles['product-column-item']} onClickCapture={this.onProductItem.bind(this, item)}>
                <div
                    className={classNames(
                        styles['img-box'],
                        {
                            [styles['img-box-hide']]: imageLoaded
                        }
                    )}
                    style={{height: item.Height}}>
                    <img
                        className={classNames(
                            styles['pro-img'],
                            {
                                [styles['pro-img-show']]: imageLoaded
                            }
                        )}
                        src={item.GoodsImageUrl}
                        alt=""
                        onLoad={this.onImageLoad.bind(this)}/>
                </div>
                <div className={styles['info-box']}>
                    <h2 className={styles['pro-title']}>{item.GoodsName}</h2>
                    <p className={styles['pro-desc']}>{item.GoodsDetail}</p>
                    <div className={styles['feature-box']}>
                        <div className={styles['price-box']}>
                            <p className={styles['current-price']}><span className={styles['current-price-yuan']}>￥</span>{item.MinPrice}</p>
                            {/*<span className={styles['origin-price']}>￥{item.GoodsGroup[0].SpecDetail[0].GoodsOrgPrice}</span>*/}
                        </div>
                        <div className={styles['cart-box']}>
                            <img className={styles['cart']} src={cart} alt="" onClick={this.onCart.bind(this, item)}/>
                            <div
                                className={styles['cart-product-img']}
                                style={{
                                    display: (startAnimation && activeCartId === item.Id) ? 'block' : 'none',
                                    backgroundImage: `url(${item.GoodsImageUrl})`,
                                    transform: activeCartId === item.Id
                                        ? `translate3d(${cartAnimationModel.tx}px,${cartAnimationModel.ty}px,0)`
                                        : 'translate3d(0,0,0)'
                                }}
                                onTransitionEnd={this.onCartProductImgTransitionEnd.bind(this)}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProductItem.defaultProps = {
    item: {},
    activeCartId: -1,
    cartAnimationModel: {}
};
ProductItem.propTypes = {
    item: PropTypes.object,
    activeCartId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    cartAnimationModel: PropTypes.object,
    startAnimation: PropTypes.bool,
    onProductItem: PropTypes.func,
    onCart: PropTypes.func,
    onCartProductImgTransitionEnd: PropTypes.func
};

export default ProductItem
