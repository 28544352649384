import React from 'react'
import { connect } from 'react-redux'
import { addToCart, delItems, modifyItemCount } from '../../actions/carts'
import { delAddress } from '../../actions/address'
import { copyData, getItemIndex, hasItem, getSourceArrayField } from '../../utils/common'
import { defaultId } from '../../constants/global'
import event from '../../utils/event'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'
import { loadOptions } from '../../utils/common-api'
import { Toast } from 'antd-mobile'
import FabCart from '../../components/fab-cart'
import FabBackTop from '../../components/fab-back-top'
import CartPopup from '../../components/cart-popup'
import ProductSection from '../../components/product-section'
import SkeletonProductSection from '../../components/skeleton/product-section'
import AtLoadMore from '../../components/load-more'
import fallsDecorator from '../../hocs/fallsDecorator'
import backTopDecorator from '../../hocs/backTopDecorator'

import styles from './success.module.scss'
import successPng from '../../images/common/success.png'

let _tx = 0, _ty = 0;
let startTimer = null, endTimer = null;

function resetCartVars() {
    _tx = 0;
    _ty = 0;
}

const mapStateToProps = store => ({
    carts: store.carts,
    userInfo: store.userInfo
});
const mapDispatchToProps = dispatch => ({
    addToCart: item => {
        dispatch(addToCart(item));
    },
    modifyItemCount: (index, stepperVal) => {
        dispatch(modifyItemCount(index, stepperVal));
    },
    delItems: () => {
        dispatch(delItems());
    },
    delAddress: () => {
        dispatch(delAddress());
    },
});
class OrderSuccess extends React.Component {
    state = {
        cartAnimationModel: {
            tx: 0,
            ty: 0
        },
        startAddToCartAnimation: false,
        showCartPopup: false,
        addItem: {},
    }
    componentDidMount() {
        this.fabCart = React.createRef();

        setTimeout(() => {
            event.emit('init', api.getGoodsLikeList + `?GoodsId=${defaultId}&MemberId=${this.props.userInfo.Id || defaultId}`);

            this.props.delItems();
            this.props.delAddress();
        }, 50);
    }
    componentWillUnmount() {
        event.removeAllListeners();
    }
    onLinkHome() {
        this.props.history.replace('/');
    }
    onLinkOrderList() {
        this.props.history.replace('/orderList');
    }
    onProductItem(item) {
        this.props.history.replace(`/productDetail/${item.Id}`);
    }
    onFabBackTop() {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    closeCartPopup() {
        resetCartVars();
        this.setState({ showCartPopup: false });
    }
    async resetAddItem(item) {
        let _obj = await loadOptions(item);
        const originGoodsImageUrl = getSourceArrayField(this.props.fallsData.falls, item, 'GoodsImageUrl');

        let _addItem = copyData(item);
        _addItem.GoodsImageUrl = originGoodsImageUrl;
        _addItem.GoodsNum = _obj.GoodsNum;
        _addItem.GoodsPrice = null;

        this.setState({
            addItem: _addItem
        });
    }
    async onOptionTag(optionsTag) {
        let _addItem;
        if (optionsTag.length === this.state.addItem.GoodsGroup.length) {
            let res = await Fetch.get(api.getGoodsSpecDetailGroupInfo + `?GoodsGroup=${JSON.stringify(optionsTag)}`);
            _addItem = {
                ...this.state.addItem,
                ...res.data,
                GoodsImageUrl:
                    res.data.GoodsImageUrl
                        ? res.data.GoodsImageUrl
                        : getSourceArrayField(this.props.fallsData.falls, this.state.addItem, 'GoodsImageUrl')
            };
            this.setState({
                addItem: _addItem
            });
        } else {
            this.resetAddItem(this.state.addItem);
        }
    }
    onConfirm(optionsTag, stepperVal) {
        if (startTimer) {
            clearTimeout(startTimer);
        }
        if (endTimer) {
            clearTimeout(endTimer);
        }
        if (optionsTag.length !== this.state.addItem.GoodsGroup.length) {
            Toast.info('请选择完整规格', 1);
        } else {
            console.log(optionsTag);
            const { addItem } = this.state;
            let _addItem = copyData(addItem);
            _addItem.stepperVal = Math.min(_addItem.GoodsNum, stepperVal);
            _addItem.optionsTag = optionsTag;
            this.setState({
                showCartPopup: false,
                addItem: _addItem
            });
            startTimer = setTimeout(() => {
                this.setState({ startAddToCartAnimation: true });
            }, 450);
            endTimer = setTimeout(() => {
                this.setState((state) => ({
                    cartAnimationModel: {
                        ...state.cartAnimationModel,
                        tx: _tx,
                        ty: _ty
                    }
                }));
                resetCartVars();
            }, 500);
        }
    }
    async onCart(item, cartRect) {
        let fabCartRect = this.fabCart.current.getBoundingClientRect();
        let offsetRight = (fabCartRect.right - cartRect.right) - cartRect.width / 2;
        let offsetBottom = (fabCartRect.bottom - cartRect.bottom) - cartRect.height / 2;

        _tx = offsetRight;
        _ty = offsetBottom;

        let _addItem = await loadOptions(item);

        this.setState({
            showCartPopup: true,
            addItem: _addItem
        });
    }
    updateCart() {
        const { addItem } = this.state;
        let result = hasItem(this.props.carts, addItem);
        if (!result) {
            this.props.addToCart(addItem);
        } else {
            let modifyItemIndex = getItemIndex(this.props.carts, addItem);
            let modifyStepperVal = addItem.stepperVal + this.props.carts[modifyItemIndex].stepperVal;
            this.props.modifyItemCount(modifyItemIndex, modifyStepperVal);
        }
    }
    onCartProductImgTransitionEnd() {
        this.setState((state) => ({
            cartAnimationModel: {
                ...state.cartAnimationModel,
                tx: 0,
                ty: 0
            },
            startAddToCartAnimation: false
        }));
        this.updateCart();
    }
    renderTipsBox() {
        return (
            <div className={styles['tips-box']}>
                <img className={styles['tips-img']} src={successPng} alt=""/>
                <h2 className={styles['tips-text']}>支付成功</h2>
                <button className={styles['tips-button']} onClick={this.onLinkOrderList.bind(this)}>确认订单</button>
            </div>
        )
    }
    renderFAB() {
        const { backTopData, carts } = this.props;
        const cartsCount = carts
            .map(el => el.stepperVal || 1)
            .reduce((acc, cur) => acc + cur, 0);

        return (
            <div className={styles['fab-groups']}>
                <FabCart
                    fabCartRef={this.fabCart}
                    count={cartsCount} />
                <FabBackTop
                    show={backTopData.showBackTop}
                    onClick={this.onFabBackTop.bind(this)} />
            </div>
        )
    }
    renderRecommendBox() {
        const { fallsData } = this.props;
        const { cartAnimationModel, startAddToCartAnimation } = this.state;

        return (
            <div className='recommend-box'>
                <div className='recommend-title'>你可能还喜欢</div>
                { fallsData.falls.length > 0
                    ? <ProductSection
                        list={fallsData.falls}
                        startAnimation={startAddToCartAnimation}
                        cartAnimationModel={cartAnimationModel}
                        onProductItem={this.onProductItem.bind(this)}
                        onCart={this.onCart.bind(this)}
                        onCartProductImgTransitionEnd={this.onCartProductImgTransitionEnd.bind(this)} />
                    : <SkeletonProductSection />
                }
            </div>
        )
    }
    renderCartPopup() {
        const { showCartPopup, addItem } = this.state;

        return (
            <CartPopup
                show={showCartPopup}
                item={addItem}
                onConfirm={this.onConfirm.bind(this)}
                onClose={this.closeCartPopup.bind(this)}
                onOptionTag={this.onOptionTag.bind(this)} />
        )
    }
    renderLoadMore() {
        const { fallsExtra } = this.props;

        return (
            <div className='load-more-wrapper'>
                <AtLoadMore status={fallsExtra.loadMoreStatus} />
            </div>
        )
    }
    render() {
        return (
            <>
                {this.renderTipsBox()}
                {this.renderFAB()}
                {this.renderRecommendBox()}
                {this.renderCartPopup()}
                {this.renderLoadMore()}
            </>
        )
    }
}

OrderSuccess = connect(mapStateToProps, mapDispatchToProps)(OrderSuccess) || OrderSuccess;
OrderSuccess = fallsDecorator(OrderSuccess) || OrderSuccess;
OrderSuccess = backTopDecorator(OrderSuccess) || OrderSuccess;
export default OrderSuccess