import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import BillsItem from '../bills-item'

import styles from './index.module.scss'
import rightArrow from '../../images/icons/right-arrow.png'

class OrderListItem extends React.Component {
    onItem(order, item) {
        this.props.onItem(order, item);
    }
    onLink(item) {
        this.props.onLink(item);
    }
    render() {
        const { item, billsItemField, itemClassName } = this.props;
        const billsItemProps = { field: billsItemField, className: itemClassName };

        return (
            <div className={styles['order-list-item']}>
                <div className={styles['top-box']}>
                    <p className={styles['order-no-text']}>订单号：{item.OrderNo}</p>
                    <div className={styles['top-right-box']} onClick={this.onLink.bind(this, item)}>
                        <p className={styles['top-right-text']}>{item.OrderStatusName}</p>
                        <img className={styles['top-right-img']} src={rightArrow} alt=""/>
                    </div>
                </div>
                {
                    item.OrderDetail.map((order, index) => {
                        return (
                            <BillsItem
                                key={`order-detail-${index}`}
                                item={order}
                                onClick={this.onItem.bind(this, order, item)}
                                {...billsItemProps}>
                                <p className={styles['bills-item-right-text']}>x{order.GoodsNum}</p>
                            </BillsItem>
                         )
                    })
                }
                <div className={styles['bottom-box']}>
                    <div className={styles['bottom-left-box']}>
                        <p className={styles['bottom-left-main-text']}>
                            合计：￥{item.OrderPrice}
                        </p>
                    </div>
                    <div className={styles['bottom-right-box']}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}

OrderListItem.defaultProps = {
    item: {},
    billsItemField: {}
};
OrderListItem.propTypes = {
    item: PropTypes.object.isRequired,
    billsItemField: PropTypes.object,
    itemClassName: PropTypes.string,
    onItem: PropTypes.func,
    onLink: PropTypes.func,
};

export default OrderListItem
