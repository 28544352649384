import React from 'react'
import Fetch from '../../utils/fetch-config'
import api from '../../utils/api'
import StepItem from '../../components/step-item'

import styles from './logistics.module.scss'

class Logistics extends React.Component {
    state = {
        stepListHeight: 0,
        queryParams: {},
        logisticsObject: {
            list: []
        },
    }
    componentDidMount() {
        const { orderNo } = this.props.match.params;
        if (this.props.location.hasOwnProperty('query')) {
            this.setState({
                queryParams: this.props.location.query
            });
        }
        this.getStepListHeight();
        this.loadLogistics(orderNo);
    }
    getStepListHeight() {
        this.setState({
            stepListHeight: document.documentElement.clientHeight - 124
        })
    }
    async loadLogistics(orderNo) {
        let res = await Fetch.get(api.getLogisticsList + `?OrderNo=${orderNo}`);
        if (res.data.resultcode != 200) return;
        this.setState({
            logisticsObject: res.data.result
        });
    }
    renderInfoBox() {
        const { logisticsObject, queryParams } = this.state;

        return (
            <div className={styles['info-box']}>
                <div className={styles['goods-img']} style={{backgroundImage: `url(${queryParams.goodsImg})`}}>
                    <div className={styles['goods-count-tag']}>
                        共{queryParams.totalCount}件商品
                    </div>
                </div>
                <div className={styles['info-main']}>
                    <p className={styles['info-text']}>物流状态：<span className={styles['info-status']}>{logisticsObject.status_detail}</span></p>
                    <p className={styles['info-text']}>快递公司：{logisticsObject.company && `${logisticsObject.company}快递`}</p>
                    <p className={styles['info-text']}>运单编号：{logisticsObject.no}</p>
                </div>
            </div>
        )
    }
    renderStepList() {
        const { logisticsObject } = this.state;
        let list = logisticsObject.list.map(item => ({...item, statusDetail: logisticsObject.status_detail}));

        return (
            <div className={styles['step-list']} style={{minHeight: this.state.stepListHeight}}>
                {
                    list.map((item, index) => {
                        return (
                            <StepItem
                                key={`step-item-${index}`}
                                item={item} />
                        )
                    })
                }
            </div>
        )
    }
    render() {
        return (
            <>
                {this.renderInfoBox()}
                {this.renderStepList()}
            </>
        )
    }
}

export default Logistics