import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import {
    hasItem,
    getItemIndex,
    copyData,
    accAdd,
    accSub,
    accMul,
    getString,
    isEmptyObject
} from '../../utils/common'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { get as getGlobalData } from '../../utils/global-data'
import { ENV_ALIPAY, ENV_WEAPP } from '../../utils/compat'
import { useIntegral, integral2money, INVITE_ID, defaultId } from '../../constants/global'
import { Stepper, Modal, List } from 'antd-mobile'
import BillsItem from '../../components/bills-item'

import './bill.scss'
import styles from './bill.module.scss'
import add from '../../images/icons/add.png'
import rightArrow from '../../images/icons/right-arrow.png'

function getTotalMoney(billCarts) {
    return billCarts.reduce((acc, cur) => accAdd(acc, accMul(cur.stepperVal, cur.GoodsPrice)), 0);
}
function getFinalMoney(billCarts) {
    return Math.max(
        accSub(
            getTotalMoney(billCarts),
            getGlobalData(integral2money) || 0
        ),
        0
    ) ;
}
function getMaxIntegral(billCarts) {
    return billCarts
        .filter(el => el.UseIntegral)
        .reduce((acc, cur) => accAdd(acc, cur.MaxUseIntergralCount), 0);
}

const mapStateToProps = store => ({
    checkedAddressItem: store.address,
    userInfo: store.userInfo,
    appType: store.appType
});
let cachedBillCarts = [];
class OrderBill extends React.Component {
    state = {
        billCarts: [],
        addressList: [],
        messageValue: '',
    }
    componentDidMount() {
        let dataSource;
        if (this.props.location.hasOwnProperty('query')) {
            if (this.props.location.query.hasOwnProperty('billCarts')) {
                dataSource = this.props.location.query.billCarts.slice();
            }
        } else {
            dataSource = cachedBillCarts.slice();
        }
        let billCarts = [...dataSource];
        console.log(billCarts);
        this.setState({
            billCarts
        });
        this.loadAddressList();
    }
    componentWillUnmount() {
        cachedBillCarts = [...this.state.billCarts];
    }
    async loadAddressList() {
        let res = await Fetch.get(api.getMemberAddressList + `?MemberId=${this.props.userInfo.Id}`);
        this.setState({
            addressList: res.data
        })
    }
    onLinkIntegralDeduct() {
        this.props.history.push({ pathname: '/integralDeduct', query: { maxIntegral: getMaxIntegral(this.state.billCarts) } });
    }
    onLinkAddressItem() {
        this.props.history.push({ pathname: '/addressItem', query: { showDefault: this.state.addressList.length > 0 } });
    }
    onLinkAddressManage() {
        this.props.history.push('/addressManage');
    }
    onStepperChange(item, val) {
        const { billCarts } = this.state;
        let _billCarts = copyData(billCarts);
        let result = hasItem(_billCarts, item);
        if (!result) {
            throw new Error(`You don't have item in billCarts.`);
        } else {
            let modifyItemIndex = getItemIndex(_billCarts, item);
            _billCarts[modifyItemIndex].stepperVal = val;
            this.setState({
                billCarts: _billCarts
            });
        }
    }
    onMessageChange(e) {
        this.setState({
            messageValue: e.target.value
        });
    }
    getOrderModel() {
        const { checkedAddressItem } = this.props;
        const { messageValue, billCarts, addressList } = this.state;

        let memberAddressId =
            isEmptyObject(checkedAddressItem)
                ? addressList.length > 0
                    ? addressList[0].Id
                    : defaultId
                : checkedAddressItem.Id;
        let goodsDetailList = [...billCarts].map(item => ({
            GoodsId: item.Id,
            GoodsSpecDetailGroupId: item.GoodsSpecDetailGroupId,
            GoodsPrice: item.GoodsPrice,
            stepperVal: item.stepperVal
        }));

        return {
            MemberAddressId: memberAddressId,
            GoodsRemark: messageValue,
            GoodsDetailList: goodsDetailList,
            TotalMoney: getFinalMoney(billCarts),
            CouponsPrice: 0,
            UseIntegralPrice: getGlobalData(integral2money) || 0,
            UseIntegralCount: getGlobalData(useIntegral) || 0,
            FromMemberId: getGlobalData(INVITE_ID) || defaultId
        };
    }
    onSubmit() {
        const { addressList, billCarts } = this.state;
        const realOrderList = billCarts.filter(item => !item.VirtualOrder);
        // 含有实物订单的都应该填写地址
        if (realOrderList.length > 0) {
            if (!addressList.length) {
                Modal.alert('温馨提示', '请先填写收货地址');
                return;
            }
        }

        switch (this.props.appType) {
            case ENV_WEAPP:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/orderV2/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
                break;
            case ENV_ALIPAY:
                window.my.navigateTo({
                    url: `/pages/order/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
                break;
            default:
                window.wx.miniProgram.navigateTo({
                    url: `/pages/orderV2/confirm/index?data=${encodeURIComponent(JSON.stringify(this.getOrderModel()))}`
                });
        }
    }
    renderEmptyAddress() {
        return (
            <div className={styles['bill-address-wrapper']} onClick={this.onLinkAddressItem.bind(this)}>
                <div className={styles['bill-address-box']}>
                    <img className={styles['add-icon']} src={add} alt=""/>
                    <div className={styles['bill-empty-info-box']}>
                        <h2 className={styles['bill-empty-title']}>点击添加地址</h2>
                        <p className={styles['bill-empty-desc']}>暂无收货地址</p>
                    </div>
                    <img className={styles['right-arrow']} src={rightArrow} alt=""/>
                </div>
                <div className={styles['border-bg']}>

                </div>
            </div>
        )
    }
    renderSelectedAddress() {
        const { addressList } = this.state;
        const { checkedAddressItem } = this.props;
        const addressItem = isEmptyObject(checkedAddressItem) ? addressList[0] : checkedAddressItem;

        return (
            <div className={styles['bill-address-wrapper']} onClick={this.onLinkAddressManage.bind(this)}>
                <div className={styles['bill-address-box']}>
                    <div className={styles['bill-selected-info-box']}>
                        <div className={styles['bill-selected-top']}>
                            <h2 className={styles['bill-selected-name']}>{addressItem.RealName}</h2>
                            <h2 className={styles['bill-selected-mobile']}>{addressItem.Mobile}</h2>
                            <div className='default-tag' style={{display: addressItem.Defaulted ? 'block' : 'none'}}>默认地址</div>
                        </div>
                        <div className={styles['bill-selected-address']}>
                            <span className={styles['bill-selected-address-text']}>{getString(addressItem.ProvinceName) + getString(addressItem.CityName) + getString(addressItem.PrefecsureName)}</span>
                            <span className={styles['bill-selected-address-text']}>{addressItem.DetailAddress}</span>
                        </div>
                    </div>
                    <img className={styles['right-arrow']} src={rightArrow} alt=""/>
                </div>
                <div className={styles['border-bg']}>

                </div>
            </div>
        )
    }
    renderAddressBox() {
        const { addressList, billCarts } = this.state;
        const realOrderList = billCarts.filter(item => !item.VirtualOrder);

        return (
            <>
                { realOrderList.length > 0
                    ? !addressList.length
                        ? this.renderEmptyAddress()
                        : this.renderSelectedAddress()
                    : null
                }
            </>
        )
    }
    renderBills() {
        const { billCarts } = this.state;

        return (
            <div className={styles['bills-wrapper']}>
                {
                    billCarts.map((item, index) => {
                        return (
                            <BillsItem
                                key={`bill-carts-${index}`}
                                item={item}>
                                <Stepper
                                    className='common-stepper'
                                    showNumber
                                    min={1}
                                    max={item.GoodsNum}
                                    value={item.stepperVal}
                                    onChange={this.onStepperChange.bind(this, item)} />
                            </BillsItem>
                        )
                    })
                }
            </div>
        )
    }
    renderMessageBox() {
        return (
            <div className={styles['message-box']}>
                <p className={styles['cell-title']} style={{alignSelf: 'flex-start'}}>留言</p>
                <textarea
                    className={styles['message-textarea']}
                    placeholder='建议留言前与商家沟通确认'
                    cols="40"
                    rows="3"
                    value={this.state.messageValue}
                    onChange={this.onMessageChange.bind(this)} />
            </div>
        )
    }
    renderMainBox() {
        return (
            <div className={styles['main-box']}>
                {this.renderBills()}
                {this.renderMessageBox()}
            </div>
        )
    }
    renderCellBox1() {
        const { billCarts } = this.state;
        const realOrderList = billCarts.filter(item => !item.VirtualOrder);
        const isIntegral = billCarts.filter(el => el.UseIntegral).length > 0;
        const integralVal = getMaxIntegral(billCarts);
        const extraText = getGlobalData(useIntegral)
            ? `使用${getGlobalData(useIntegral)}积分，抵扣￥${getGlobalData(integral2money)}`
            : `${integralVal}积分可用`;

        return (
            <>
                { realOrderList.length > 0 && (
                    <div className={styles['cell-box1']}>
                        <List className='bill-list'>
                            {
                                isIntegral && (
                                    <List.Item
                                        className='bill-list-item'
                                        extra={extraText}
                                        arrow='horizontal'
                                        onClick={this.onLinkIntegralDeduct.bind(this)}>
                                        积分
                                    </List.Item>
                                )
                            }
                        </List>
                        <div
                            className={classNames(
                                styles['cell-group'],
                                styles['cell-group1']
                            )}
                            style={{borderBottom: '1px solid #E5E5E5'}}>
                            <p className={styles['cell-title']}>配送方式</p>
                            <p className={styles['cell-text-grey']}>快递免邮</p>
                        </div>
                        <div
                            className={classNames(
                                styles['cell-group'],
                                styles['cell-group1']
                            )}>
                            <p className={styles['cell-title']}>质量保证</p>
                            <p className={styles['cell-text-grey']}>7天包退换货</p>
                        </div>
                    </div>
                )}
            </>
        )
    }
    renderCellBox2() {
        const { billCarts } = this.state;
        const realOrderList = billCarts.filter(item => !item.VirtualOrder);

        return (
            <>
                { realOrderList.length > 0 && (
                    <div className={styles['cell-box2']}>
                        <div
                            className={classNames(
                                styles['cell-group'],
                                styles['cell-group2']
                            )}>
                            <p className={styles['cell-title']}>商品总额</p>
                            <p className={styles['cell-text-price']}>￥{getTotalMoney(this.state.billCarts)}</p>
                        </div>
                        <div
                            className={classNames(
                                styles['cell-group'],
                                styles['cell-group2']
                            )}>
                            <p className={styles['cell-title']}>运费</p>
                            <p className={styles['cell-text-black']}>免邮</p>
                        </div>
                    </div>
                )}
            </>
        )
    }
    renderBillBar() {
        return (
            <div className={styles['bill-bar--blank']}>
                <div className={styles['bill-bar']}>
                    <div className={styles['price-info-box']}>
                        <p className={styles['price-desc']}>实付款：</p>
                        <p className={styles['price-text']}>
                            <span className={styles['price-text-yuan']}>￥</span>
                            {getFinalMoney(this.state.billCarts)}
                        </p>
                    </div>
                    <button className={styles['submit-button']} onClick={this.onSubmit.bind(this)}>提交订单</button>
                </div>
            </div>
        )
    }
    render() {
        return (
            <>
                {this.renderAddressBox()}
                {this.renderMainBox()}
                {this.renderCellBox1()}
                {this.renderCellBox2()}
                {this.renderBillBar()}
            </>
        )
    }
}

OrderBill = connect(mapStateToProps)(OrderBill) || OrderBill;
export default OrderBill