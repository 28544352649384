import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getTimeObject, formatTime } from '../../utils/common'

import './index.scss'

class StepItem extends React.Component {
    render() {
        const { item } = this.props;
        // const timeObject = getTimeObject(item.datetime);
        const dateStr = formatTime(new Date(item.datetime));

        return (
            <div className='step-item-box'>
                {/*<div className='step-item-left'>
                    <p className='step-date'>{`${timeObject.month}-${timeObject.day}`}</p>
                    <p className='step-hour'>{`${timeObject.hour}:${timeObject.minute}`}</p>
                </div>*/}
                <div className='step-item-right'>
                    <div className='step-circle'>

                    </div>
                    <div className='step-text-group'>
                        <h2 className='step-title'>{item.statusDetail}</h2>
                        <p className='step-desc'>{item.remark}</p>
                        <p className='step-date-str'>{dateStr}</p>
                    </div>
                </div>
            </div>
        );
    }
}

StepItem.defaultProps = {
    item: {}
};
StepItem.propTypes = {
    item: PropTypes.object
};

export default StepItem
