import { combineReducers } from 'redux'
import carts from './carts'
import userInfo from './userInfo'
import address from './address'
import version from './version'
import appType from './appType'

export default combineReducers({
    carts,
    userInfo,
    address,
    version,
    appType
})