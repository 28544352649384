import React from 'react'
import classNames from 'classnames'
import { List, InputItem, Modal } from 'antd-mobile'
import { accMul, accDiv } from '../../utils/common'
import { set as setGlobalData, get as getGlobalData } from '../../utils/global-data'
import { useIntegral, integral2money } from '../../constants/global'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'

import './deduct.scss'
import styles from './deduct.module.scss'
import diamond from '../../images/icons/diamond.png'

class IntegralDeduct extends React.Component {
    state = {
        rate: 0,
        wrapperHeight: document.documentElement.clientHeight,
        integralVal: getGlobalData(useIntegral) || 0,
        resultMoney: getGlobalData(integral2money) || 0,
        maxIntegral: 0,
    }
    componentDidMount() {
        let { maxIntegral } = this.props.location.query;
        this.setState({
            maxIntegral
        });
        this.loadRate();
    }
    async loadRate() {
        let res = await Fetch.get(api.getIntegralAlgorithm);
        this.setState({
            rate: accDiv(res.data.DeductionMoney, res.data.IntegralNum)
        });
    }
    onIntegralChange(value) {
        const maxValue = this.state.maxIntegral;
        const _value = Math.min(value, maxValue);

        this.setState((state) => ({
            integralVal: _value,
            resultMoney: accMul(_value, state.rate)
        }));
    }
    allIn(value) {
        this.setState((state) => ({
            integralVal: value,
            resultMoney: accMul(value, state.rate)
        }));
    }
    onConfirm() {
        const { integralVal, resultMoney } = this.state;

        if (!integralVal && integralVal != 0) {
            Modal.alert('温馨提示', '请输入使用积分');
        } else {
            setGlobalData(useIntegral, integralVal);
            setGlobalData(integral2money, resultMoney);
            this.props.history.goBack();
        }
    }
    renderInputBox() {
        const { integralVal } = this.state;

        return (
            <div className={styles['input-box']}>
                <p className={styles['input-title']}>使用积分</p>
                <List className='input-list'>
                    <InputItem
                        type='number'
                        className='input-list-item'
                        value={integralVal}
                        onChange={this.onIntegralChange.bind(this)}
                    >
                        <img src={diamond} alt="" />
                    </InputItem>
                </List>
            </div>
        )
    }
    renderListBox() {
        const { resultMoney, maxIntegral } = this.state;

        return (
            <div className={styles['list-box']}>
                <div className={styles['list-box-item']}>
                    <p className={styles['list-box-item-title']}>当前商品的可使用积分：{maxIntegral}</p>
                    <p className={styles['list-box-item-feature-text']} onClick={this.allIn.bind(this, maxIntegral)}>全部抵扣</p>
                </div>
                <List className='custom-list'>
                    <List.Item
                        className='custom-list-item'
                        extra={`￥${resultMoney}`}>
                        当前抵扣
                    </List.Item>
                </List>
            </div>
        )
    }
    renderButtonBox() {
        return (
            <div className='fixed-bottom-box--blank'>
                <div className='fixed-bottom-box'>
                    <button className={styles['confirm-button']} onClick={this.onConfirm.bind(this)}>确认抵扣</button>
                </div>
            </div>
        )
    }
    render() {
        return (
            <div className={styles['deduct-wrapper']} style={{height: this.state.wrapperHeight}}>
                {this.renderInputBox()}
                {this.renderListBox()}
                {this.renderButtonBox()}
            </div>
        )
    }
}

export default IntegralDeduct