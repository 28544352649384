import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { getTimeObject } from '../../utils/common'

import './index.scss'
import styles from './index.module.scss'
import CommentImages from '../comment-images'
import Like from '../like'
import Star from '../star'

class CommentItem extends React.Component {
    state = {
        isLikeOn: false
    }
    onLike(item) {
        let { isLikeOn } = this.state;
        this.setState({
            isLikeOn: !isLikeOn
        }, () => {
            this.props.onLike(item, this.state.isLikeOn);
        })
    }
    render() {
        const { isLikeOn } = this.state;
        const { item, onPreview } = this.props;
        const timeObject = getTimeObject(item.AddDate);

        return (
            <div className={classNames(
                styles['comment-list-item'],
                'comment-list-item'
            )}>
                <div className={styles['comment-list-item-left']}>
                    <img className={styles['comment-avatar']} src={item.AvatarUrl} alt=""/>
                </div>
                <div className={styles['comment-list-item-right']}>
                    <div className={styles['comment-right-top']}>
                        <div className={styles['comment-title-box']}>
                            <h2 className={styles['comment-title']}>{item.Nick}</h2>
                            <p className={styles['comment-detail-text']}>{item.GoodsName}·{item.GoodsOrderNum}个</p>
                        </div>
                        <Star
                            className='comment-star-box'
                            readOnly
                            value={item.Star} />
                    </div>
                    <div className={styles['comment-desc-text']}>
                        {item.CommentDesc}
                    </div>
                    <CommentImages list={item.GoodsComment} onPreview={onPreview} />
                    { item.ReviewComment && (
                        <div>
                            <div className={styles['comment-attach-box']}>
                                <p className={styles['comment-attach-title']}>追加评论</p>
                                <div className={styles['comment-desc-text']}>
                                    {item.ReviewComment.ReviewDesc}
                                </div>
                            </div>
                            <CommentImages list={item.ReviewComment.ReviewImage} onPreview={onPreview} />
                        </div>
                    )}
                    { item.ReplyDesc && (
                        <div className={styles['comment-reply-box']}>
                            <p className={styles['comment-reply-title']}>掌柜回复：</p>
                            <p className={styles['comment-reply-desc']}>{item.ReplyDesc}</p>
                        </div>
                    )}
                    <div className={styles['comment-bottom-box']}>
                        <div className={styles['comment-time']}>
                            {`${timeObject.month}-${timeObject.day}`} {`${timeObject.hour}:${timeObject.minute}`}
                        </div>
                        <div className={styles['comment-features-box']}>
                            <div className={styles['feature-group']} style={{width: '53px'}} onClick={this.onLike.bind(this, item)}>
                                <Like isLikeOn={isLikeOn} />
                                <span className={classNames(
                                    styles['feature-text'],
                                    {
                                        [styles['feature-text--likeon']]: isLikeOn
                                    }
                                )}>
                                    {isLikeOn ? item.Likes + 1 : (item.Likes || '点赞')}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='h_line'></div>
                </div>
            </div>
        );
    }
}

CommentItem.defaultProps = {
    item: {}
};
CommentItem.propTypes = {
    item: PropTypes.object,
    onLike: PropTypes.func,
    onPreview: PropTypes.func
};

export default CommentItem
