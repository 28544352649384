import React from 'react'
import CustomRouter from './router'

import './App.scss'

function App() {
    return (
        <CustomRouter />
    );
}

export default App;
