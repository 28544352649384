import React from 'react'

export default function backTopDecorator(WrappedComponent) {
    let timer;
    let handleScroll;
    return class backTopDecorator extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                showBackTop: false
            };
            this.scrollFunc = this.scrollFunc.bind(this);
        }
        componentDidMount() {
            handleScroll = () => {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(this.scrollFunc, 50);
            };

            window.addEventListener('scroll', handleScroll, false);
        }
        componentWillUnmount() {
            window.removeEventListener('scroll', handleScroll, false);
            timer = null;
            handleScroll = null;
        }
        scrollFunc() {
            let clientHeight  = document.documentElement.clientHeight;
            let scrollTop = document.documentElement.scrollTop;

            if (scrollTop >= clientHeight * 1.5) {
                if (!this.state.showBackTop) {
                    this.setState({ showBackTop: true });
                }
            } else {
                if (this.state.showBackTop) {
                    this.setState({ showBackTop: false });
                }
            }
        }
        render() {
            return <WrappedComponent backTopData={this.state} {...this.props} />
        }
    }
}