import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import magnifier from '../../images/icons/search.png'

class SearchInput extends React.Component {
    render() {
        const { editable, placeholder, onLink } = this.props;

        return (
            <div className={styles['search-wrapper']} onClick={onLink}>
                <img className={styles.magnifier} src={magnifier} alt='搜索' />
                <div
                    className={classNames(
                        styles['input-wrapper'],
                        {
                            [styles['input-wrapper--uneditable']]: !editable
                        }
                    )}
                    data-attr={placeholder}>
                    <input
                        className={styles.input}
                        type='text' />
                </div>
            </div>
        );
    }
}

SearchInput.defaultProps = {
    editable: true,
};
SearchInput.propTypes = {
    editable: PropTypes.bool,
    placeholder: PropTypes.string,
    onLink: PropTypes.func
};

export default SearchInput
