import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'

class ScrollTab extends React.Component {
    onChangeTab = (index) => {
        this.props.onChangeTab(index);
    }
    render() {
        const { tabs, activeIndex, fields } = this.props;

        return (
            <div className={styles['scroll-tabs']}>
                {
                    tabs.map((item, index) => {
                        return (
                            <div
                                key={`scroll-tab-${index}`}
                                className={classNames(
                                    styles['scroll-tab'],
                                    {
                                        [styles['scroll-tab--active']]: activeIndex === index
                                    }
                                )}
                                onClick={this.onChangeTab.bind(this, index)}>
                                {item[fields.name] || item}
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

ScrollTab.defaultProps = {
    tabs: [],
    activeIndex: 0,
    fields: {},
};
ScrollTab.propTypes = {
    tabs: PropTypes.array,
    activeIndex: PropTypes.number,
    fields: PropTypes.object,
    onChangeTab: PropTypes.func,
};

export default ScrollTab
