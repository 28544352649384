import React from 'react'
import { mergeObjects } from '../utils/common'
import { NOMORE, LOADING } from '../constants/global'

export default function loadMoreDecorator(WrappedComponent) {
    return class loadMoreDecorator extends WrappedComponent {
        constructor(props) {
            super(props);
            this.state = mergeObjects(this.state, {
                pageIndex: 1,
                hasMore: true,
                initLoad: true,
                loadMoreStatus: LOADING,
            })
        }
        initLoadMore() {
            return new Promise(resolve => {
                this.setState({
                    pageIndex: 1,
                    hasMore: true,
                    initLoad: true,
                    loadMoreStatus: LOADING
                }, () => resolve());
            })
        }
        noMore() {
            this.setState({
                hasMore: false,
                loadMoreStatus: NOMORE
            });
        }
        nextPage() {
            return new Promise(resolve => {
                this.setState((state) => ({
                    pageIndex: ++state.pageIndex,
                    initLoad: false
                }), () => resolve());
            })
        }
    }
}