import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { addToCart, modifyItemCount, modifyItemChecked, delItems } from '../../actions/carts'
import { delAddress } from '../../actions/address'
import { accAdd, accMul, copyData, getItemIndex, hasItem, getSourceArrayField } from '../../utils/common'
import event from '../../utils/event'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { loadOptions } from '../../utils/common-api'
import { defaultId } from '../../constants/global'
import { Stepper, Modal, Toast } from 'antd-mobile'
import BillsItem from '../../components/bills-item'
import ProductSection from '../../components/product-section'
import CartPopup from '../../components/cart-popup'
import EmptyBox from '../../components/empty-box'
import SkeletonProductSection from '../../components/skeleton/product-section'
import AtLoadMore from '../../components/load-more'
import fallsDecorator from '../../hocs/fallsDecorator'

import './cart.scss'
import styles from './cart.module.scss'
import checkedPng from '../../images/icons/checked.png'
import cartEmpty from '../../images/common/cart-empty.png'

let startTimer = null, endTimer = null;

function getTotalMoney(carts) {
    return carts
        .filter(item => item.checked)
        .reduce((acc, cur) => accAdd(acc, accMul(cur.stepperVal, cur.GoodsPrice)), 0);
}
function getTotlaCounts(carts) {
    return carts.filter(item => item.checked).length;
}
const mapStateToProps = store => ({
    carts: store.carts,
    userInfo: store.userInfo
});
const mapDispatchToProps = dispatch => ({
    addToCart: item => {
        dispatch(addToCart(item));
    },
    modifyItemCount: (index, stepperVal) => {
        dispatch(modifyItemCount(index, stepperVal));
    },
    modifyItemChecked: (index, checked) => {
        dispatch(modifyItemChecked(index, checked));
    },
    delItems: () => {
        dispatch(delItems());
    },
    delAddress: () => {
        dispatch(delAddress());
    },
});
class Cart extends React.Component {
    state = {
        isAllChecked: false,
        isEdit: false,
        showCartPopup: false,
        addItem: {},
    }
    componentDidMount() {
        this.props.delAddress();

        this.updateStatus();
        const goodsId = this.props.carts.length > 0 ? this.props.carts[0].Id : defaultId;
        setTimeout(() => event.emit('init', api.getGoodsLikeList + `?GoodsId=${goodsId}&MemberId=${this.props.userInfo.Id}`), 50);
    }
    componentWillUnmount() {
        event.removeAllListeners();
    }
    updateStatus() {
        this.setState({
            isAllChecked: this.props.carts.length > 0 && this.props.carts.every(item => item.checked)
        });
    }
    onChangeEditStatus() {
        this.setState((state) => ({
            isEdit: !state.isEdit
        }))
    }
    onLinkHome() {
        this.props.history.replace('/');
    }
    onItem(item, e) {
        let targetParentHtml = e.target.parentNode.outerHTML;
        // 阻止捕获到stepper组件
        if (targetParentHtml.indexOf('bills') === -1) return;
        this.props.history.push(`/productDetail/${item.Id}`);
    }
    onItemChecked(item) {
        const { carts } = this.props;
        let result = hasItem(carts, item);
        if (!result) {
            throw new Error(`You don't have item in billCarts.`);
        } else {
            let modifyItemIndex = getItemIndex(carts, item);
            this.props.modifyItemChecked(modifyItemIndex, !item.checked);
            let flag = this.props.carts.length > 0 && this.props.carts.every(item => item.checked);
            this.setState({ isAllChecked: flag });
        }
    }
    onAllChecked() {
        const { carts } = this.props;
        let flag = carts.length > 0 && carts.every(item => item.checked);
        carts.forEach((_, index) => {
            this.props.modifyItemChecked(index, !flag);
        });
        this.setState({ isAllChecked: !flag });
    }
    onDelItems() {
        if (this.props.carts.some(item => item.checked)) {
            Modal.alert('温馨提示', '您确定要删除选中的商品吗？', [
                { text: '我再想想' },
                { text: '确认', onPress: () => {
                        this.props.delItems();
                        setTimeout(() => {
                            this.updateStatus();
                            // 清空购物车，退出编辑状态
                            if (this.props.carts.length === 0) {
                                this.setState({
                                    isEdit: false
                                });
                            }
                        }, 50);
                    }
                }
            ]);
        } else {
            Toast.info('请先选中商品', 1);
        }
    }
    onSettle() {
        const { carts } = this.props;
        const hasCheckedItem = carts.some(item => item.checked);
        if (!hasCheckedItem) {
            Toast.info('请先选择商品', 1);
        } else {
            this.props.history.push({pathname: '/orderBill', query: { billCarts: carts.filter(item => item.checked) }});
        }
    }
    onStepperChange(item, val) {
        const { carts } = this.props;
        let result = hasItem(carts, item);
        if (!result) {
            throw new Error(`You don't have item in billCarts.`);
        } else {
            let modifyItemIndex = getItemIndex(carts, item);
            this.props.modifyItemCount(modifyItemIndex, val);
        }
    }
    onProductItem(item) {
        this.props.history.push(`/productDetail/${item.Id}`);
    }
    async onCart(item) {
        let _addItem = await loadOptions(item);

        this.setState({
            showCartPopup: true,
            addItem: _addItem
        });
    }
    closeCartPopup() {
        this.setState({ showCartPopup: false });
    }
    async resetAddItem(item) {
        let _obj = await loadOptions(item);
        const originGoodsImageUrl = getSourceArrayField(this.props.fallsData.falls, item, 'GoodsImageUrl');

        let _addItem = copyData(item);
        _addItem.GoodsImageUrl = originGoodsImageUrl;
        _addItem.GoodsNum = _obj.GoodsNum;
        _addItem.GoodsPrice = null;

        this.setState({
            addItem: _addItem
        });
    }
    async onOptionTag(optionsTag) {
        let _addItem;
        if (optionsTag.length === this.state.addItem.GoodsGroup.length) {
            let res = await Fetch.get(api.getGoodsSpecDetailGroupInfo + `?GoodsGroup=${JSON.stringify(optionsTag)}`);
            _addItem = {
                ...this.state.addItem,
                ...res.data,
                GoodsImageUrl:
                    res.data.GoodsImageUrl
                        ? res.data.GoodsImageUrl
                        : getSourceArrayField(this.props.fallsData.falls, this.state.addItem, 'GoodsImageUrl')
            };
            this.setState({
                addItem: _addItem
            });
        } else {
            this.resetAddItem(this.state.addItem);
        }
    }
    updateCart() {
        return new Promise(resolve => {
            const { addItem } = this.state;
            let result = hasItem(this.props.carts, addItem);
            if (!result) {
                this.props.addToCart(addItem);
                resolve();
            } else {
                let modifyItemIndex = getItemIndex(this.props.carts, addItem);
                let modifyStepperVal = addItem.stepperVal + this.props.carts[modifyItemIndex].stepperVal;
                this.props.modifyItemCount(modifyItemIndex, modifyStepperVal);
                resolve();
            }
        });
    }
    onConfirm(optionsTag, stepperVal) {
        if (startTimer) {
            clearTimeout(startTimer);
        }
        if (endTimer) {
            clearTimeout(endTimer);
        }
        if (optionsTag.length !== this.state.addItem.GoodsGroup.length) {
            Toast.info('请选择完整规格', 1);
        } else {
            const { addItem } = this.state;
            let _addItem = copyData(addItem);
            _addItem.stepperVal = Math.min(_addItem.GoodsNum, stepperVal);
            _addItem.optionsTag = optionsTag;
            this.setState({
                showCartPopup: false,
                addItem: _addItem
            }, () => {
                this.updateCart().then(() => this.updateStatus());
                Toast.success('加入购物车成功', 1);
            });
        }
    }
    renderEditBar() {
        return (
            <div className={styles['edit-bar']}>
                <p className={styles['edit-bar-left']}>共{this.props.carts.length}件商品</p>
                <p className={styles['edit-bar-right']} onClick={this.onChangeEditStatus.bind(this)}>{this.state.isEdit ? '完成' : '编辑'}</p>
            </div>
        )
    }
    renderEmptyBox() {
        return (
            <div className={styles['empty-box']}>
                <EmptyBox
                    emptyImage={cartEmpty}
                    emptyText='购物车空空如也~'
                    emptyImageClassName={styles['empty-box-img']} />
                <button className='back-home-button' onClick={this.onLinkHome.bind(this)}>去商城逛逛吧</button>
            </div>
        )
    }
    renderCarts() {
        const { carts } = this.props;

        return (
            <div className={styles['carts']}>
                {
                    carts.map((item, index) => {
                        return (
                            <div key={`carts-item-${index}`} className={styles['carts-item']}>
                                <img
                                    className={classNames(
                                        styles['carts-checked-png'],
                                        'checked-png',
                                        {
                                            'checked-png--checked': !!item.checked
                                        }
                                    )}
                                    src={!!item.checked ? checkedPng : ''}
                                    onClick={this.onItemChecked.bind(this, item)}
                                    alt="" />
                                <BillsItem
                                    className='carts-item-box'
                                    item={item}
                                    onClick={this.onItem.bind(this, item)}>
                                    <Stepper
                                        className='common-stepper-v2'
                                        showNumber
                                        min={1}
                                        max={item.GoodsNum}
                                        value={item.stepperVal}
                                        onChange={this.onStepperChange.bind(this, item)} />
                                </BillsItem>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    renderRecommendBox() {
        const { fallsData } = this.props;

        return (
            <div className='recommend-box'>
                <div className='recommend-title'>你可能还喜欢</div>
                { fallsData.falls.length > 0
                    ? <ProductSection
                        list={fallsData.falls}
                        onProductItem={this.onProductItem.bind(this)}
                        onCart={this.onCart.bind(this)} />
                    : <SkeletonProductSection />
                }
            </div>
        )
    }
    renderCartPopup() {
        const { showCartPopup, addItem } = this.state;

        return (
            <CartPopup
                show={showCartPopup}
                item={addItem}
                onConfirm={this.onConfirm.bind(this)}
                onClose={this.closeCartPopup.bind(this)}
                onOptionTag={this.onOptionTag.bind(this)} />
        )
    }
    renderBottomBox() {
        const { carts } = this.props;
        const { isAllChecked, isEdit } = this.state;

        return (
            <div className='fixed-bottom-box--blank'>
                <div className={classNames('fixed-bottom-box', styles['carts-fixed-bottom-box'])}>
                    <div className={styles['bottom-left']} onClick={this.onAllChecked.bind(this)}>
                        <img
                            className={classNames(
                                styles['bottom-checked-png'],
                                'checked-png',
                                {
                                    'checked-png--checked': isAllChecked
                                }
                            )}
                            src={isAllChecked ? checkedPng : ''}
                            alt="" />
                        <p className={styles['bottom-p1']}>全选</p>
                    </div>
                    <div className={styles['bottom-main']}>
                        <div className={styles['bottom-main-left']} style={{display: isEdit ? 'none' : 'flex'}}>
                            <p className={styles['bottom-p2']}>合计：</p>
                            <p className={styles['bottom-p3']}><span className={styles['bottom-p3-yuan']}>￥</span>{getTotalMoney(carts)}</p>
                        </div>
                        <button className={styles['bottom-button']} style={{display: isEdit ? 'none' : 'block'}} onClick={this.onSettle.bind(this)}>确认结算{getTotlaCounts(carts) ? `(${getTotlaCounts(carts)})` : ''}</button>
                        <button className={styles['bottom-button']} style={{display: isEdit ? 'block' : 'none'}} onClick={this.onDelItems.bind(this)}>删除选中</button>
                    </div>
                </div>
            </div>
        )
    }
    renderLoadMore() {
        const { fallsExtra } = this.props;

        return (
            <div className='load-more-wrapper'>
                <AtLoadMore status={fallsExtra.loadMoreStatus} />
            </div>
        )
    }
    render() {
        const { carts } = this.props;

        return (
            <>
                { carts.length > 0 && this.renderEditBar()}
                { !carts.length && this.renderEmptyBox()}
                {this.renderCarts()}
                {this.renderRecommendBox()}
                {this.renderLoadMore()}
                { carts.length > 0 && this.renderBottomBox()}
                {this.renderCartPopup()}
            </>
        )
    }
}

Cart = connect(mapStateToProps, mapDispatchToProps)(Cart) || Cart;
Cart = fallsDecorator(Cart) || Cart;
export default Cart