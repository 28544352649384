import {
    ADD_TO_CART,
    MODIFY_ITEM_COUNT,
    MODIFY_ITEM_CHECKED,
    DEL_ITEMS,
    DEL_ALL
} from '../constants/carts'

export default function cart(state = [], action) {
    switch (action.type) {
        case ADD_TO_CART:
            return [...state, action.item];
        case MODIFY_ITEM_COUNT:
            let _arr = [...state];
            _arr[action.index].stepperVal = action.stepperVal;
            return _arr;
        case MODIFY_ITEM_CHECKED:
            let _arr2 = [...state];
            _arr2[action.index].checked = action.checked;
            return _arr2;
        case DEL_ITEMS:
            return [...state].filter(item => !item.checked);
        case DEL_ALL:
            return [];
        default:
            return state;
    }
}