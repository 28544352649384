import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import ProductItem from '../product-item'

import styles from './index.module.scss'

class ProductSection extends React.Component {
    state = {
        activeCartId: -1
    }
    onCart(item, cartRect) {
        this.setState({ activeCartId: item.Id });
        this.props.onCart(item, cartRect);
    }
    render() {
        const { list, cartAnimationModel, startAnimation, onProductItem, onCartProductImgTransitionEnd } = this.props;
        const { activeCartId } = this.state;
        const productItemProps = {
            activeCartId,
            cartAnimationModel,
            startAnimation,
            onProductItem,
            onCartProductImgTransitionEnd
        };

        return (
            <div className={styles['product-section']}>
                {
                    list.map((column, column_index) => {
                        return (
                            <div key={`product-column-${column_index}`} className={styles['product-column']}>
                                {
                                    column.map((product, product_index) => {
                                        return (
                                            <ProductItem
                                                key={`product-${product_index}`}
                                                item={product}
                                                onCart={this.onCart.bind(this)}
                                                {...productItemProps} />
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

ProductSection.defaultProps = {
    list: [],
    cartAnimationModel: {},
};
ProductSection.propTypes = {
    list: PropTypes.array,
    cartAnimationModel: PropTypes.object,
    startAnimation: PropTypes.bool,
    onProductItem: PropTypes.func,
    onCart: PropTypes.func,
    onCartProductImgTransitionEnd: PropTypes.func
};

export default ProductSection
