import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { List, InputItem, TextareaItem, Picker, Switch, Modal, Toast } from 'antd-mobile'
import { createForm } from 'rc-form'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { defaultId } from '../../constants/global'

import './item.scss'
import styles from './item.module.scss'

const mapStateToProps = store => ({
    userInfo: store.userInfo
});
class AddressItem extends React.Component {
    state = {
        screenHeight: 0,
        isDefault: false,
        addressItem: {},
        showDefaultSwitch: false,
        isEdit: false,
        district: [],
    }
    componentDidMount() {
        let addressItem = {};
        let showDefault = false;
        let isEdit = false;
        if (this.props.location.hasOwnProperty('query')) {
            if (this.props.location.query.hasOwnProperty('item')) {
                addressItem = this.props.location.query.item;
            }
            if (this.props.location.query.hasOwnProperty('showDefault')) {
                showDefault = this.props.location.query.showDefault;
            }
            if (this.props.location.query.hasOwnProperty('isEdit')) {
                isEdit = this.props.location.query.isEdit;
            }
        }
        this.props.form.setFieldsValue({
            consignee: addressItem.RealName,
            mobile: addressItem.Mobile,
            district: addressItem.ProvinceId ? [addressItem.ProvinceId, addressItem.CityId, addressItem.PrefecsureId] : '',
            address: addressItem.DetailAddress,
            isDefault: addressItem.Defaulted
        });
        this.setState({
            addressItem,
            showDefaultSwitch: !!showDefault,
            isEdit: !!isEdit
        });
        this.initScreenHeight();
        this.loadDistrict();
    }
    initScreenHeight() {
        this.setState({
            screenHeight: document.documentElement.clientHeight
        });
    }
    async loadDistrict() {
        let res = await Fetch.get(api.getCityListById);
        this.setState({
            district: res.data
        });
    }
    async editMemberAddressInfo(model) {
        let { isDefault, district, address, mobile, consignee } = model;
        let [ProvinceId, CityId, PrefecsureId] = district;
        // 添加新地址会设置当前为默认地址
        let _isDefault = !this.state.showDefaultSwitch ? true : isDefault;
        let res = await Fetch.post(api.editMemberAddressInfo, {
            Id: this.state.addressItem.Id || defaultId,
            MemberId: this.props.userInfo.Id,
            Defaulted: _isDefault,
            ProvinceId,
            CityId,
            PrefecsureId,
            DetailAddress: address,
            RealName: consignee,
            Mobile: mobile,
            AddressStatus: !!this.state.isEdit ? 2 : 1
        });
        if (res.data) {
            Toast.info('保存成功', 1, () => {
                this.props.history.goBack();
            });
        } else {
            Toast.info('保存出错，请退出重试');
        }
    }
    validateMobile(rule, value, callback) {
        if (typeof value === 'string' && !(/^1[3456789]\d{9}$/.test(value.replace(/\s/g, '')))) {
            callback(new Error('手机号码格式不正确'));
        } else {
            callback();
        }
    }
    onSubmit() {
        this.props.form.validateFields((error, value) => {
            // 单独验证区域选择
            let truthList = [];
            let valueKeys = Object.keys(value);
            valueKeys.forEach(key => {
                if ((key !== 'district' && key !== 'isDefault') && value[key]) {
                    truthList.push(true);
                }
            });
            if (!value['district'] && truthList.length === valueKeys.length - 2) {
                Modal.alert('温馨提示', '请选择市县乡/镇等');
                return false;
            }
            if (!error) {
                this.editMemberAddressInfo(value);
            }
        });
    }
    async onDeleteAddress() {
        Modal.alert('温馨提示', '您确定要删除当前收货地址吗？', [
            { text: '取消' },
            { text: '确认', onPress: async () => {
                let res = await Fetch.get(api.deleteMemberAddressById + `?AddressId=${this.state.addressItem.Id}`);
                if (res.data) {
                    Toast.info('删除成功', 1, () => {
                        this.props.history.goBack();
                    });
                } else {
                    Toast.info('删除出错，请退出重试');
                }
            }
            }
        ]);
    }
    renderFormFields() {
        const { getFieldProps, getFieldError, getFieldValue } = this.props.form;

        return (
            <div style={{width: '100%'}}>
                <List className='address-cell-box'>
                    <InputItem
                        {...getFieldProps('consignee', {
                            rules: [{ required: true, message: '请填写收件人姓名' }]
                        })}
                        error={!!getFieldError('consignee')}
                        onErrorClick={() => {
                            Modal.alert('温馨提示', getFieldError('consignee').join('、'));
                        }}
                        type='text'
                        placeholder='请填写收件人姓名'>
                        收件人
                    </InputItem>
                </List>
                <List className='address-cell-box'>
                    <InputItem
                        {...getFieldProps('mobile', {
                            rules: [
                                { required: true, message: '请填写收件人手机号' },
                                { validator: this.validateMobile.bind(this) }
                            ]
                        })}
                        error={!!getFieldError('mobile')}
                        onErrorClick={() => {
                            Modal.alert('温馨提示', getFieldError('mobile').join('、'));
                        }}
                        type='phone'
                        placeholder='请填写收件人手机号'>
                        手机号码
                    </InputItem>
                </List>
                <List className={classNames(
                    'address-cell-box',
                    {
                        'has-district-value': getFieldValue('district')
                    }
                )} >
                    <Picker
                        {...getFieldProps('district')}
                        extra='请选择市县乡/镇等'
                        data={this.state.district}>
                        <List.Item arrow='horizontal'>收件地区</List.Item>
                    </Picker>
                </List>
                <List className='address-cell-box'>
                    <TextareaItem
                        {...getFieldProps('address', {
                            rules: [{ required: true, message: '请填写详细街道门牌号等' }]
                        })}
                        error={!!getFieldError('address')}
                        onErrorClick={() => {
                            Modal.alert('温馨提示', getFieldError('address').join('、'));
                        }}
                        title='详细地址'
                        placeholder='请填写详细街道门牌号等'
                        rows={3}
                    />
                </List>
            </div>
        )
    }
    renderDefaultAddress() {
        const { getFieldProps } = this.props.form;

        return (
            <List className='default-box' style={{display: this.state.showDefaultSwitch ? 'block' : 'none'}}>
                <List.Item
                    extra={
                        <Switch
                            {...getFieldProps('isDefault', {
                                initialValue: this.state.isDefault,
                                valuePropName: 'checked'
                            })}
                            color='#D6A05E' />
                    }>
                    设置为默认地址
                </List.Item>
            </List>
        )
    }
    renderFeatureBox() {
        const { isEdit, showDefaultSwitch } = this.state;

        return (
            <div className={styles['feature-box']}>
                {this.renderDefaultAddress()}
                <button className={styles['save-button']} onClick={this.onSubmit.bind(this)} style={{marginTop: showDefaultSwitch ? '30px' : '20px'}}>{showDefaultSwitch ? '保存地址' : '保存并使用'}</button>
                <button className={styles['del-button']} onClick={this.onDeleteAddress.bind(this)} style={{display: isEdit ? 'block' : 'none'}}>删除地址</button>
            </div>
        )
    }
    render() {
        return (
            <div  className={styles['page-box']} style={{height: this.state.screenHeight}}>
                {this.renderFormFields()}
                {this.renderFeatureBox()}
            </div>
        )
    }
}

AddressItem = connect(mapStateToProps)(AddressItem) || AddressItem;
AddressItem = createForm()(AddressItem) || AddressItem;
export default AddressItem