const WeChat = 'WeChat';
const Alipay = 'AlipayClient';

export const ENV_WEAPP = 1;
export const ENV_ALIPAY = 2;

export const isWx = navigator.userAgent.toLowerCase().indexOf(WeChat.toLowerCase()) > -1;
export const isAli = navigator.userAgent.toLowerCase().indexOf(Alipay.toLowerCase()) > -1;

export const getAvatarUrl = (userInfo: any) => {
    if (isAli) {
        return userInfo && userInfo.AliAvatar;
    }
    return userInfo && userInfo.AvatarUrl;
};
export const getNick = (userInfo: any) => {
    if (isAli) {
        return userInfo && userInfo.AliNickName;
    }
    return userInfo && userInfo.Nick;
};