import React from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'
import { checkedAddress } from '../../actions/address'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { getString, isEmptyObject } from '../../utils/common'
import EmptyBox from '../../components/empty-box'

import styles from './manage.module.scss'
import edit from '../../images/icons/edit.png'
import checkedPng from '../../images/icons/checked.png'
import addressEmpty from '../../images/common/address-empty.png'

const mapStateToProps = store => ({
    checkedAddressItem: store.address,
    userInfo: store.userInfo
});
const mapDispatchToProps = dispatch => ({
    checkedAddress: item => {
        dispatch(checkedAddress(item));
    },
});
class AddressManage extends React.Component {
    state = {
        screenHeight: 0,
        addressList: [],
    }
    componentDidMount() {
        this.initScreenHeight();
        this.loadAddressList();
    }
    initScreenHeight() {
        this.setState({
            screenHeight: document.documentElement.clientHeight
        });
    }
    async loadAddressList() {
        let res = await Fetch.get(api.getMemberAddressList + `?MemberId=${this.props.userInfo.Id}`);
        let _addresList = [...res.data];

        const { checkedAddressItem } = this.props;
        if (isEmptyObject(checkedAddressItem)) {
            if (_addresList.length > 0) {
                _addresList[0].checked = true;
            }
        } else {
            _addresList.forEach( element => element.checked = element.Id === checkedAddressItem.Id);
        }

        this.setState({
            addressList: _addresList
        })
    }
    onAddressItem(item) {
        const { addressList } = this.state;
        let _addressList = [...addressList];
        _addressList.forEach(element => element.checked = element.Id === item.Id);
        this.props.checkedAddress(item);
        this.setState({
            addressList: _addressList
        }, () => {
            setTimeout(() => this.props.history.goBack(), 300);
        });
    }
    onLinkAddressItem(item, showDefault, isEdit, e) {
        e.stopPropagation();
        this.props.history.push({ pathname: '/addressItem', query: { item, showDefault, isEdit } });
    }
    renderAddressList() {
        const { addressList } = this.state;

        return (
            <div className={styles['address-list']} style={{minHeight: this.state.screenHeight}}>
                { addressList.length > 0
                    ? addressList.map((item, index) => {
                        return (
                            <div key={`address-item-${index}`} className={styles['address-item']} onClick={this.onAddressItem.bind(this, item)}>
                                <img
                                    className={classNames(
                                        'checked-png',
                                        {
                                            'checked-png--checked': !!item.checked
                                        }
                                    )}
                                    src={!!item.checked ? checkedPng : ''}
                                    alt="" />
                                <div className={styles['address-item-info-box']}>
                                    <div className={styles['address-item-top']}>
                                        <h2 className={styles['address-item-name']}>{item.RealName}</h2>
                                        <h2 className={styles['address-item-mobile']}>{item.Mobile}</h2>
                                        <div className='default-tag' style={{display: item.Defaulted ? 'block' : 'none'}}>默认地址</div>
                                    </div>
                                    <div className={styles['address-item-address']}>
                                        <span className={styles['address-item-address-text']}>{getString(item.ProvinceName) + getString(item.CityName) + getString(item.PrefecsureName)}</span>
                                        <span className={styles['address-item-address-text']}>{item.DetailAddress}</span>
                                    </div>
                                </div>
                                <img className={styles['edit-png']} src={edit} alt="" onClick={this.onLinkAddressItem.bind(this, item, addressList.length > 0, true)} />
                            </div>
                        )
                    })
                    : <EmptyBox className={styles['address-empty-box']} emptyImage={addressEmpty} emptyText='暂无收货地址' />
                }
            </div>
        )
    }
    renderBottomBox() {
        return (
            <div className='fixed-bottom-box--blank'>
                <div className='fixed-bottom-box'>
                    <button className={styles['add-address-button']} onClick={this.onLinkAddressItem.bind(this, {}, this.state.addressList.length > 0, false)}>添加新地址</button>
                </div>
            </div>
        )
    }
    render() {
        return (
            <>
                {this.renderAddressList()}
                {this.renderBottomBox()}
            </>
        )
    }
}

AddressManage = connect(mapStateToProps, mapDispatchToProps)(AddressManage) || AddressManage;
export default AddressManage