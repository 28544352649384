import React from 'react'
import ReachBottomDecorator from './reachBottomDecorator'
import Fetch from '../utils/fetch-config'
import event from '../utils/event'
import { NOMORE, LOADING } from '../constants/global'

export default function fallsDecorator(WrappedComponent) {
    let EnhancedWrappedComponent = ReachBottomDecorator(WrappedComponent) || WrappedComponent;
    let url;
    let pageIndex = 1;
    let hasMore = true;
    let initLoad = true;
    let loadMoreStatus = LOADING;
    return class fallsDecorator extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                falls: [],
                fallHeights: [],
            };
        }
        componentDidMount() {
            event.on('init', (_url) => {
                url = _url;
                this.initData(_url, true);
            });
            event.on('changeTab', (_url) => {
                url = _url;
                this.initData(_url, true);
            });
            event.on('reachBottom', async () =>{
                if (!hasMore) return;
                initLoad = false;
                let res = await Fetch.get(url  + `&PageIndex=${++pageIndex}`);
                if (Array.isArray(res.data) && res.data.length === 0) {
                    hasMore = false;
                    loadMoreStatus = NOMORE;
                }
                this.handleFallsData(res.data, 0);
            });
        }
        componentWillUnmount() {
            event.removeAllListeners();
        }
        async initData(apiUrl, isUpdate) {
            pageIndex = 1;
            hasMore = true;
            initLoad = true;
            loadMoreStatus = LOADING;
            let res = await Fetch.get(apiUrl + `&PageIndex=${pageIndex}`);
            let { falls } = this.state;
            // 初始化两列数组
            let col = 2;
            for (let i = 0; i < col; i++) {
                falls[i] = [];
            }
            let updateObject = {};
            if (isUpdate) {
                updateObject.fallHeights = [];
            }
            this.setState({
                ...updateObject,
                falls
            }, () => this.handleFallsData(res.data));
        }
        async handleFallsData(data, col = 2) {
            if (!data) return;
            let { falls, fallHeights } = this.state;
            let screenWidth = document.documentElement.clientWidth;
            let productWidth = (screenWidth - 30) * 0.4811;
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                item.Height = (productWidth / item.Width * item.Height) || 0;    // 计算图片的等比例高度
                // 两列数据及高度收集
                if (i < col) {
                    falls[i][0] = item;
                    fallHeights.push(item.Height);
                    this.setState({ fallHeights });
                }
                // 找出最小高度的位置，进行添加元素，并更新高度集
                // 注：高度集只有两个数据，代表各列的图片总高度
                else {
                    let { fallHeights } = this.state;
                    let minHeight = Math.min.apply(null, fallHeights);
                    let minHeightIndex = fallHeights.indexOf(minHeight);
                    let index = falls[minHeightIndex].filter(Boolean).length;
                    falls[minHeightIndex][index] = item;
                    fallHeights[minHeightIndex] += item.Height;
                    this.setState({ fallHeights });
                }
            }
            this.setState({ falls });
        }
        render() {
            return <EnhancedWrappedComponent
                fallsData={this.state}
                fallsExtra={{
                    pageIndex,
                    hasMore,
                    initLoad,
                    loadMoreStatus
                }}
                {...this.props} />
        }
    }
}