import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'

class SkeletonBannerSection extends React.Component {
    render() {
        return (
            <div className='skeleton-banner-section'>
                <div className='banner-block'>

                </div>
                <div className='brand-box'>
                    {
                        [2,3,3].map((item, index) => {
                            return (
                                <div
                                    key={`skeleton-banner-section-brand-box-item-${index}`}
                                    className='brand-box-item'>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

SkeletonBannerSection.defaultProps = {

};
SkeletonBannerSection.propTypes = {

};

export default SkeletonBannerSection
