import Fetch from './fetch-config'
import api from './api'

async function loadOptions(item: any) {
    let res = await Fetch.get(api.getGoodsSpecDetailGroupList + `?GoodsId=${item.Id}`);
    return {
        ...item,
        ...res.data
    };
}

export {
    loadOptions
}