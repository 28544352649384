import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './index.module.scss'
import backTop from '../../images/icons/back-top.png'

class FabBackTop extends React.Component {
    render() {
        const { show, onClick } = this.props;

        return (
            <div
                className='fab-box'
                style={{
                    display: show ? 'flex' : 'none',
                    marginTop: '13px'
                }}
                onClick={onClick}>
                <div className={styles['fab-back-top-box']}>
                    <img className={styles['fab-back-top']} src={backTop} alt=""/>
                    <span className={styles['fab-back-top-text']}>顶部</span>
                </div>
            </div>
        );
    }
}

FabBackTop.defaultProps = {

};
FabBackTop.propTypes = {
    show: PropTypes.bool,
    onClick: PropTypes.func
};

export default FabBackTop
