import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './index.scss'

function serializeOptionsTag(optionsTag = []) {
    return optionsTag
        .map((item) => item.GoodsSpecDetailName)
        .join("; ");
}
class BillsItem extends React.Component {
    render() {
        const { item, field, className, onClick } = this.props;

        /**
         * @description 返回输出用的规格信息
         * 由于存在前端自定对象，以及后端给的对象规范不一
         * 所以就有了以下的处理两者输出规格的统一方法
         * 注： serializeOptionsTag方法用于输出前端自定对象，
         * 其余即是输出后端给的固定字段： GoodsSpecDetailName, GoodsSpecDetailName1
         * @param item
         * @returns {string}
         */
        function getOptionsTag(item) {
            if (item.GoodsSpecDetailName) {
                if (item.GoodsSpecDetailName1) {
                    return `${item.GoodsSpecDetailName}; ${item.GoodsSpecDetailName1}`;
                } else {
                    return `${item.GoodsSpecDetailName}`;
                }
            } else {
                if (item.GoodsSpecDetailName1) {
                    return `${item.GoodsSpecDetailName1}`;
                } else {
                    return serializeOptionsTag(item.optionsTag);
                }
            }
        }
        
        return (
            <div className={classNames('bills-item', className)} onClick={onClick}>
                <div className={'bills-item-img'} style={{backgroundImage: `url(${item.GoodsImageUrl})`}}>

                </div>
                <div className={'bills-item-main'}>
                    <h2 className={'bills-item-title'}>{item[field.goodsName]}</h2>
                    <p className={'bills-item-option-tag'}>
                        {
                            getOptionsTag(item)
                        }
                    </p>
                    <div className={'bills-item-feature'}>
                        <p className={'bills-price'}>
                            <span className={'bills-price-yuan'}>￥</span>
                            {item[field.goodsPrice]}
                        </p>
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}

BillsItem.defaultProps = {
    item: {},
    field: {
        goodsName: 'GoodsName',
        goodsPrice: 'GoodsPrice'
    }
};
BillsItem.propTypes = {
    item: PropTypes.object.isRequired,
    field: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default BillsItem