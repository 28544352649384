import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'
import cart from '../../images/icons/shop-cart.png'

class FabCart extends React.Component {
    render() {
        const { count, fabCartRef } = this.props;

        return (
            <Link to={'/cart'} ref={fabCartRef} className='fab-box'>
                <div className={styles['fab-cart-box']}>
                    <img className={styles['fab-cart']} src={cart} alt=""/>
                    { count > 0 && (
                        <span className={classNames(
                            'k-tag',
                            styles['fab-tag']
                        )}>
                            {count}
                        </span>
                    )}
                </div>
            </Link>
        );
    }
}

FabCart.defaultProps = {
    count: 0
};
FabCart.propTypes = {
    count: PropTypes.number,
    fabCartRef: PropTypes.any
};

export default FabCart
