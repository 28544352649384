import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import SkeletonProductItem from '../product-item'

import './index.scss'

class SkeletonProductSection extends React.Component {
    render() {
        return (
            <div className='skeleton-product-section'>
                {
                    [2,3,3].map((_, index) => {
                        return <SkeletonProductItem key={`skeleton-product-section-item-${index}`} />
                    })
                }
            </div>
        );
    }
}

SkeletonProductSection.defaultProps = {

};
SkeletonProductSection.propTypes = {

};

export default SkeletonProductSection
