import React from 'react'
import { connect } from 'react-redux'
import api, { apiUrl } from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { Toast } from 'antd-mobile'
import CustomLoadingIcon from '../../components/custom-loading-icon'
import PreviewMask from '../../components/preview-mask'
import UploadPicker from '../../components/upload-picker'
import Star from '../../components/star'

import './comment.scss'
import styles from './comment.module.scss'
import starOn from '../../images/icons/star_on.png'
import starOff from '../../images/icons/star_off.png'

const mapStateToProps = store => ({
    userInfo: store.userInfo
});

class Comment extends React.Component {
    state = {
        wrapperHeight: document.documentElement.clientHeight,
        queryParams: {},
        files: [],
        activeType: '',
        activeUrl: '',
        starValue: 5,
        messageVal: '',
    }
    componentDidMount() {
        if (this.props.location.hasOwnProperty('query')) {
            this.setState({
                queryParams: this.props.location.query
            });
        }
    }
    // 上传文件
    uploadFiles(files) {
        if (!files.length) return;
        let data = new FormData();
        files.forEach(_file => {
            data.append('file', _file.file, _file.file.name);
        });

        // 原生fetch，封装的fetch没有封装文件上传，so...
        const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
        return new Promise(resolve => {
            fetch(baseUrl + api.uploadGoodsCommentFile, {
                method: 'post',
                body: data,
            })
                .then(res => res.json())
                .then(data => {
                    resolve(data);
                })
        })
    }
    onChange(files, type, index) {
        this.setState({
            files
        });
    }
    onItemClick(index, files) {
        const dataUrl = files[index].url;
        let activeType = dataUrl.indexOf("image") > -1
            ? "image"
            : dataUrl.indexOf("video") > -1
            ? "video"
            : "image";

        this.setState({
            activeUrl: dataUrl,
            activeType
        })
    }
    onClosePreviewMask() {
        this.setState({
            activeUrl: '',
        });
    }
    onMsgChange(e) {
        this.setState({
            messageVal: e.target.value
        });
    }
    onStarChange(value) {
        this.setState({
            starValue: value
        });
    }
    async onPostMessage() {
        const { queryParams, starValue, messageVal, files } = this.state;
        const { userInfo } = this.props;

        let uploadData = [];
        if (files.length > 0) {
            Toast.loading(<CustomLoadingIcon text='上传中...' />, 1000);
            let res = await this.uploadFiles(files);
            uploadData = res.data;
        }

        Fetch.post(api.createGoodsComment, {
            OrderDetail: queryParams.orderDetail,
            MemberId: userInfo.Id,
            OrderNo: queryParams.orderNo,
            CommentDesc: messageVal,
            Star: starValue,
            list: uploadData
        }).then(res => {
            if (res.data) {
                Toast.hide();
                Toast.info('发表成功', 1, () => {
                    this.props.history.goBack();
                });
            } else {
                Toast.info(res.message);
            }
        })
    }
    renderGoodInfo() {
        const { queryParams, starValue } = this.state;

        return (
            <div className={styles['good-info-box']}>
                <div className={styles['good-img']} style={{ backgroundImage: `url(${queryParams.goodsImg})` }}>

                </div>
                <div className={styles['info-box']}>
                    <h2 className={styles['good-name']}>{queryParams.goodsName}</h2>
                    <Star
                        className='good-star-box'
                        size={30}
                        value={starValue}
                        starOn={starOn}
                        starOff={starOff}
                        onChange={this.onStarChange.bind(this)} />
                </div>
            </div>
        )
    }
    renderPostBox() {
        const { messageVal } = this.state;

        return (
            <div className={styles['post-box']}>
                <textarea
                    className={styles['msg-textarea']}
                    rows={3}
                    placeholder={`亲，你对本次的消费还满意吗？从多个角度评价宝贝，可以帮助更多想购买的小伙伴哦☺`}
                    value={messageVal}
                    onChange={this.onMsgChange.bind(this)} />
                {this.renderUploadPicker()}
            </div>
        )
    }
    renderPreviewMask() {
        const { activeType, activeUrl } = this.state;

        return (
            <PreviewMask
                dataType={activeType}
                dataUrl={activeUrl}
                onClose={this.onClosePreviewMask.bind(this)} />
        )
    }
    renderUploadPicker() {
        const { files } = this.state;

        return (
            <UploadPicker
                files={files}
                multiple
                capture
                length={3}
                selectable={files.length < 7}
                onChange={this.onChange.bind(this)}
                onItemClick={this.onItemClick.bind(this)} />
        )
    }
    render() {
        return (
            <div className={styles['main-wrapper']} style={{minHeight: this.state.wrapperHeight}}>
                {this.renderGoodInfo()}
                <div className='h_line'>

                </div>
                {this.renderPostBox()}
                {this.renderPreviewMask()}
                <button className={styles['post-button']} onClick={this.onPostMessage.bind(this)}>发表</button>
            </div>
        )
    }
}

Comment = connect(mapStateToProps)(Comment) || Comment;
export default Comment