import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'
import ScrollToTop from './ScrollToTop'
import Index from '../pages/index'
import Search from '../pages/search/search'
import ProductDetail from '../pages/product/detail'
import ProductPoster from '../pages/product/poster'
import OrderBill from '../pages/order/bill'
import OrderDetail from '../pages/order/detail'
import OrderSuccess from '../pages/order/success'
import OrderList from '../pages/order/list'
import AddressItem from '../pages/address/item'
import AddressManage from '../pages/address/manage'
import Cart from '../pages/cart/cart'
import Logistics from '../pages/logistics/logistics'
import Comment from '../pages/comment/comment'
import CommentList from '../pages/comment/list'
import IntegralDeduct from '../pages/integral/deduct'

const prodPath = '/';

class CustomRouter extends React.Component {
    render() {
        return (
            <Router basename={process.env.NODE_ENV === 'production' ? prodPath : '/'}>
                <Switch>
                    <ScrollToTop>
                        <Route exact path='/' component={Index} />
                        <Route path='/search' component={Search} />
                        <Route path='/productDetail/:goodsId' component={ProductDetail} />
                        <Route path='/productPoster' component={ProductPoster} />
                        <Route path='/orderBill' component={OrderBill} />
                        <Route path='/orderDetail' component={OrderDetail} />
                        <Route path='/orderSuccess' component={OrderSuccess} />
                        <Route path='/orderList' component={OrderList} />
                        <Route path='/addressItem' component={AddressItem} />
                        <Route path='/addressManage' component={AddressManage} />
                        <Route path='/cart' component={Cart} />
                        <Route path='/logistics/:orderNo' component={Logistics} />
                        <Route path='/comment' component={Comment} />
                        <Route path='/commentList' component={CommentList} />
                        <Route path='/integralDeduct' component={IntegralDeduct} />
                    </ScrollToTop>
                </Switch>
            </Router>
        )
    }
}

export default CustomRouter