import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { picScale, getVideoPosterUrl } from '../../utils/common'

import styles from './index.module.scss'
import playPng from '../../images/icons/play.png'

function _shallowEqual(a, b) {
    if (a.length !== b.length) {
        return false;
    } else {
        for (let i = 0; i < a.length; i++) {
            if (a[i].Id !== b[i].Id) {
                return false;
            }
        }
        return true;
    }
}

class CommentImages extends React.Component {
    state = {
        _list: []
    }
    componentWillMount() {
        this.updateList(this.props.list);
    }
    componentWillReceiveProps(nextProps) {
        if (!_shallowEqual(this.props.list, nextProps.list)) {
            this.updateList(nextProps.list);
        }
    }
    async updateList(list) {
        let _list = list.slice(0);

        for (let i = 0; i < _list.length; i++) {
            let item = _list[i];
            // 视频类型
            if (item.CommentType === 2) {
                item.dataUrl = await getVideoPosterUrl(item.CommentUrl);
            } else {
                item.dataUrl = item.CommentUrl;
            }
        }

        this.setState({
            _list
        });
    }
    onPreview(url, type) {
        this.props.onPreview(url, type);
    }
    render() {
        const { _list } = this.state;
        const renderList = _list.slice(0);
        const len = renderList.length;

        // 修复space-between的兼容问题
        if (len > 2 && len % 3 === 2) {
            const emptyItem = {
                dataUrl: '',
                CommentUrl: '',
                CommentType: 1
            };
            renderList.push(emptyItem);
        }

        return (
            <div className={styles['comment-images-box']}>
                {
                    renderList.map((item, index) => {
                        return (
                            <div
                                key={`comment-images-${index}`}
                                className={classNames(
                                    styles['comment-image'],
                                    {
                                        [`pic--${picScale(_list)}x`]: true
                                    }
                                )}
                                style={{backgroundImage: `url(${item.dataUrl})`}}
                                onClick={this.onPreview.bind(this, item.CommentUrl, item.CommentType)}>
                                {
                                    (item.CommentType === 2) && (
                                        <img className={styles['play-btn']} src={playPng} alt="" />
                                    )
                                }
                            </div>
                        )
                    })
                }
            </div>
        );
    }
}

CommentImages.defaultProps = {
    list: []
};
CommentImages.propTypes = {
    list: PropTypes.array.isRequired,
    onPreview: PropTypes.func
};

export default CommentImages
